export default {
    main: {
        login: 'تسجيل الدخول',
        signup: 'إنشاء حساب',
        language: 'اللغة',
        toTop: 'إلى الأعلى',
        licence: 'الترخيص',
        profile: 'الملف الشخصي',
        nodata: 'لا توجد بيانات',
        logout: 'تسجيل الخروج',
        notify: 'الإشعارات',
        all: 'الكل',
        system: 'إشعار النظام',
        activity: 'الأنشطة',
        clear: 'مسح الكل',
        more: 'تحميل المزيد',
        wallet: 'المحفظة',
        deposit: 'الإيداع',
        withdraw: 'السحب',
        trans: 'المعاملات',
        currency: 'عملة الإيداع',
        network: 'الشبكة',
        address: 'عنوان الإيداع',
        mini: 'الحد الأدنى للإيداع',
        send: 'أرسل فقط USDT إلى عنوان الإيداع هذا. سيتم إيداع العملات تلقائيًا بعد 6 تأكيدات للشبكة. لا يتم دعم عناوين العقود الذكية (اتصل بنا).',
        withAddress: 'عنوان السحب',
        withEnter: 'أدخل عنوانك',
        amount: 'مبلغ السحب',
        security: 'لأسباب أمنية، قد تستغرق عمليات السحب الكبيرة أو المشبوهة من 1 إلى 6 ساعات لإجراء عملية التدقيق. نشكركم على صبركم!',
        today: 'اليوم',
        week: 'هذا الأسبوع',
        month: 'هذا الشهر',
        year: 'هذا العام',
        myWallet: 'محفظتي',
        available: 'الرصيد المتاح',
        fee: 'الرسوم',
        chat: 'الدردشة',
        online: 'متصل',
        message: 'أدخل الرسالة',
        rules: 'القواعد'
    },
    login: {
        email: "يرجى إدخال البريد الإلكتروني",
        incorrect: "تنسيق البريد الإلكتروني غير صحيح",
        password: "يرجى إدخال كلمة المرور",
        characters: "6الكلمة السرية هي مجموعة من الأرقام والحروف بطول أكبر من",
        account: "الحساب",
        login: "تسجيل الدخول",
        register: "تسجيل",
        userEmail: "بريدك الإلكتروني",
        enterEmail: "أدخل بريدك الإلكتروني",
        userPas: "كلمة المرور الخاصة بك",
        enterPas: "أدخل كلمة المرور الخاصة بك",
        forget: "نسيت كلمة المرور؟",
        or: "أو",
        agree: "لقد قرأت ووافقت على الشروط والأحكام.",
        resAndLogin: "سجل وقم بتسجيل الدخول",
        policy: "هذا الموقع محمي بواسطة reCAPTCHA وتنطبق عليه <span>سياسة الخصوصية</span> و<span>شروط الخدمة</span> من Google."
    },
    profile: {
        password: "يرجى إدخال كلمة مرور جديدة",
        long: "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل، حرف أو مسافة، ويجب أن تكون بين 6-15 حرفًا",
        newPas: "كلمة المرور المؤكدة لا تطابق كلمة المرور الجديدة",
        original: "يرجى إدخال كلمة المرور الأصلية",
        personal: 'الإعدادات الشخصية',
        change: 'تغيير اللقب وبياناتك',
        username: 'اسم المستخدم الخاص بك',
        hide: 'إخفاء لقبي',
        private: 'الملف الشخصي الخاص',
        closing: 'إغلاق حسابك',
        closeDatail: 'يمكنك إغلاق حسابك بالنقر على زر "إغلاق حسابي" أدناه. سيتم حذف جميع بياناتك نهائيًا دون إمكانية استردادها في المستقبل (بما في ذلك أي مزايا VIP).',
        closeMy: 'إغلاق حسابي',
        social: 'اجتماعي',
        media: 'ربط وسائل التواصل الاجتماعي',
        connected: 'متصل',
        noConnect: 'غير متصل',
        settings: 'الإعدادات',
        account: 'الإعدادات العامة لحسابك',
        email: 'البريد الإلكتروني',
        verify: 'يرجى التحقق من بريدك الإلكتروني',
        factor: 'المصادقة الثنائية',
        enable: 'تمكين المصادقة الثنائية لحماية حسابك من الوصول غير المصرح به.',
        prohibit: 'حظر',
        pas: 'كلمة المرور',
        current: 'كلمة المرور الحالية',
        please: 'يرجى إدخال كلمة المرور الحالية',
        newPassword: 'كلمة المرور الجديدة',
        paleseNew: 'يرجى إدخال كلمة المرور الجديدة',
        repeat: 'أعد كلمة المرور',
        pleaseCurrent: 'يرجى إدخال كلمة المرور الحالية',
        changePas: 'تغيير كلمة المرور',
        sessions: 'الجلسات',
        devices: 'تحقق من أجهزتك',
        history: 'تاريخ اللعب',
        referrals: 'الإحالات',
        VIP: 'برنامج VIP',
        check: 'تحقق من جميع معاملاتك',
        allTrans: 'جميع المعاملات',
        withdrawal: 'السحب',
        timeframe: 'الإطار الزمني',
        today: 'اليوم',
        deposits: 'الودائع',
        chackGame: 'تحقق من جميع ألعابك',
        chain: 'سلسلة'
    },
    components: {
        search: "بحث",
        sort: "ترتيب حسب:",
        popular: "شائع",
        providers: "المزودون:",
        new: "جديد",
        hot: "الأكثر تفاعلاً",
        notice: "لا يوجد إشعار حتى الآن.",
        enable: "تمكين",
        cybet: "Cybet.com مملوكة لشركة Eskimo Adventures Ltd.، رقم التسجيل 000043034، وتقع في شارع Sea Urchin، سان بيدرو، أمبريغريس كاي، بليز. الاتصال: support{'@'}cybet.com. مرخصة من حكومة أنجوان، اتحاد جزر القمر بموجب الترخيص رقم ALSI-202409034-FI2. Cybet.com متوافقة تمامًا مع المعايير التنظيمية لعمليات الألعاب. القمار ينطوي على مخاطر مالية وقد يسبب الإدمان. يرجى القمار بشكل مسؤول. يجب أن يكون المشاركون فوق 18 عامًا للعب.",
        progress: "تقدم VIP الخاص بك",
        discover: "اكتشف تجربة الألعاب النهائية مع نظام VIP الحصري من CY.GAMES!",
        link: "رابطك",
        share: "احصل على 1000.00 دولار لكل صديق تدعوه، بالإضافة إلى عمولة تصل إلى 25% على رهاناتهم. استمتع بعمولات ثابتة، سواء فازوا أو خسروا، في كازينونا وكتابنا الرياضي. ابدأ في الكسب الآن!",
        referralLink: "رابط الإحالة الخاص بك",
        code: "رمز الإحالة الخاص بك",
        yourReferrals: "جميع الإحالات الخاصة بك",
        receive: "استلام",
        game: "جميع الألعاب",
        winnings: "الأرباح",
        loosing: "الخسائر",
        wagered: "الرهانات التي قام بها لاعبوك",
        earned: "لقد ربحت",
        nothing: "لا شيء للمطالبة به حتى الآن",
        join: "انضم إلى مجتمع اللاعبين النخبة لدينا واستمتع بأفضل ما تقدمه الألعاب عبر الإنترنت.",
        details: "تفاصيل",
        unverified: "غير مُحقق",
        verified: "تم التحقق من البريد الإلكتروني",
        type: "نوع المعاملة",
        date: "تاريخ",
        total: "إجمالي الرهان",
        methods: "طرق الدفع",
        status: "الحالة",
        device: "الجهاز",
        location: "الموقع",
        IP: "عنوان IP",
        last: "آخر استخدام",
        tableGame: "لعبة",
        bet: "رهان",
        multiplayer: "متعدد اللاعبين",
        profit: "ربح",
        searchGame: "البحث عن لعبة",
        provider: "المزودين",
        ourGames: "ألعابنا",
        allWins: "جميع الانتصارات",
        luckyWins: "الانتصارات المحظوظة",
        bigWins: "الانتصارات الكبيرة",
        latest: "أحدث الرهانات",
        player: "اسم اللاعب",
        time: "الوقت",
        amount: "المبلغ",
        coefficent: "المعامل"
    },
    wallet: {
        noAddress: 'لا يمكن أن يكون العنوان فارغًا',
        submit: 'تم تقديم طلب السحب، في انتظار التأكيد...',
        range: 'يرجى إدخال مبلغ رقمي ضمن نطاق السحب'
    }
};
