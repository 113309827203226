export default {
    main: {
        login: 'Войти',
        signup: 'Зарегистрироваться',
        language: 'Язык',
        toTop: 'Наверх',
        licence: 'Лицензия',
        profile: 'Личный профиль',
        nodata: 'Нет данных',
        logout: 'Выйти',
        notify: 'Уведомления',
        all: 'Все',
        system: 'Системное уведомление',
        activity: 'Деятельность',
        clear: 'Очистить всё',
        more: 'Загрузить больше',
        wallet: 'Кошелек',
        deposit: 'Депозит',
        withdraw: 'Вывод средств',
        trans: 'Транзакции',
        currency: 'Валюта депозита',
        network: 'Сеть',
        address: 'Адрес депозита',
        mini: 'Минимальный депозит',
        send: 'Отправляйте только USDT на этот депозитный адрес. Средства будут зачислены автоматически после 6 подтверждений сети. Адреса смарт-контрактов не поддерживаются (свяжитесь с нами).',
        withAddress: 'Адрес для вывода',
        withEnter: 'Введите ваш адрес',
        amount: 'Сумма вывода',
        security: 'Для обеспечения безопасности крупные или подозрительные выводы могут занять 1-6 часов для проверки. Благодарим за ваше терпение!',
        today: 'Сегодня',
        week: 'На этой неделе',
        month: 'В этом месяце',
        year: 'В этом году',
        myWallet: 'Мой кошелек',
        available: 'Доступно',
        fee: 'Комиссия',
        chat: 'Чат',
        online: 'онлайн',
        message: 'Введите сообщение',
        rules: 'Правила'
    },
    login: {
        email: "Пожалуйста, введите электронную почту",
        incorrect: "Формат электронной почты неверен",
        password: "Пожалуйста, введите пароль",
        characters: "Пароль - это комбинация цифр и букв длиной более 6 символов",
        account: "Аккаунт",
        login: "Войти",
        register: "Регистрация",
        userEmail: "Ваш e-mail",
        enterEmail: "Введите ваш e-mail",
        userPas: "Ваш пароль",
        enterPas: "Введите ваш пароль",
        forget: "Забыли пароль?",
        or: "или",
        agree: "Я прочитал(а) и согласен(согласна) с Условиями и Политикой конфиденциальности.",
        resAndLogin: "Зарегистрироваться и войти",
        policy: "Этот сайт защищен reCAPTCHA, и к нему применяются <span>Политика конфиденциальности</span> и <span>Условия предоставления услуг</span> Google."
    },
    profile: {
        password: "Пожалуйста, введите новый пароль",
        long: "Пароль должен содержать как минимум одну цифру, букву или пробел и быть длиной от 6 до 15 символов",
        newPas: "Подтверждение пароля не совпадает с новым паролем",
        original: "Пожалуйста, введите оригинальный пароль",
        personal: 'Личные настройки',
        change: 'Изменить ник и ваши данные',
        username: 'Ваше имя пользователя',
        hide: 'Скрыть мой ник',
        private: 'Приватный профиль',
        closing: 'Закрытие вашего аккаунта',
        closeDatail: 'Вы можете закрыть свой аккаунт, нажав на кнопку "Закрыть мой аккаунт" ниже. Все ваши данные будут удалены навсегда без возможности восстановления в будущем (включая любые VIP-привилегии).',
        closeMy: 'Закрыть мой аккаунт',
        social: 'Социальные сети',
        media: 'Подключить социальные сети',
        connected: 'Подключено',
        noConnect: 'не подключено',
        settings: 'Настройки',
        account: 'Общие настройки вашего аккаунта',
        email: 'Электронная почта',
        verify: 'Пожалуйста, подтвердите вашу электронную почту',
        factor: 'Двухфакторная аутентификация',
        enable: 'Включите двухфакторную аутентификацию, чтобы защитить ваш аккаунт от несанкционированного доступа.',
        prohibit: 'Запретить',
        pas: 'Пароль',
        current: 'Ваш текущий пароль',
        please: 'Пожалуйста, введите ваш текущий пароль',
        newPassword: 'Новый пароль',
        paleseNew: 'Пожалуйста, введите ваш новый пароль',
        repeat: 'Повторите пароль',
        pleaseCurrent: 'Пожалуйста, введите ваш текущий пароль',
        changePas: 'Изменить пароль',
        sessions: 'Сеансы',
        devices: 'Проверьте свои устройства',
        history: 'История игр',
        referrals: 'Рефералы',
        VIP: 'VIP-программа',
        check: 'Проверьте все свои транзакции',
        allTrans: 'Все транзакции',
        withdrawal: 'Вывод средств',
        timeframe: 'Период',
        today: 'Сегодня',
        deposits: 'Депозиты',
        chackGame: 'Проверьте все свои игры',
        chain: 'Цепочка'
    },
    components: {
        search: "Поиск",
        sort: "Сортировать по:",
        popular: "Популярное",
        providers: "Поставщики:",
        new: "Новое",
        hot: "Горячее",
        notice: "Уведомлений пока нет.",
        enable: "Включить",
        cybet: "Cybet.com принадлежит Eskimo Adventures Ltd., регистрационный номер 000043034, расположенной по адресу Sea Urchin Street, San Pedro, Ambergris Caye, Belize. Контакт: support{'@'}cybet.com. Лицензировано правительством Анжуана, Союз Коморских Островов, номер лицензии ALSI-202409034-FI2. Cybet.com полностью соответствует нормативным стандартам для игорных операций. Азартные игры сопряжены с финансовым риском и могут вызывать привыкание. Пожалуйста, играйте ответственно. Участники должны быть старше 18 лет, чтобы играть.",
        progress: "Ваш прогресс VIP",
        discover: "Откройте для себя лучший игровой опыт с эксклюзивной VIP-системой CY.GAMES!",
        link: "Ваша ссылка",
        share: "Получите $1 000,00 за каждого приглашенного друга, а также до 25% комиссии с их ставок. Наслаждайтесь стабильной комиссией, выигрывают они или проигрывают, в нашем Казино и Спортбуке. Начните зарабатывать уже сейчас!",
        referralLink: "Ваша реферальная ссылка",
        code: "Ваш реферальный код",
        yourReferrals: "Все ваши рефералы",
        receive: "Получить",
        game: "Все игры",
        winnings: "Выигрыши",
        loosing: "Проигрыши",
        wagered: "Ваши игроки сделали ставки на",
        earned: "Вы заработали",
        nothing: "Пока нечего получать",
        join: "Присоединяйтесь к нашему сообществу элитных игроков и наслаждайтесь лучшими возможностями онлайн-игр.",
        details: "Детали",
        unverified: "не подтверждено",
        verified: "Электронная почта подтверждена",
        type: "Тип транзакции",
        date: "Дата",
        total: "Общая ставка",
        methods: "Методы оплаты",
        status: "Статус",
        device: "Устройство",
        location: "Местоположение",
        IP: "IP-адрес",
        last: "Последнее использование",
        tableGame: "Игра",
        bet: "Ставка",
        multiplayer: "Мультиплеер",
        profit: "Прибыль",
        searchGame: "Поиск игры",
        provider: "Поставщики",
        ourGames: "Наши игры",
        allWins: "Все выигрыши",
        luckyWins: "Удачные выигрыши",
        bigWins: "Большие выигрыши",
        latest: "Последние ставки",
        player: "ИМЯ ИГРОКА",
        time: "ВРЕМЯ",
        amount: "СУММА",
        coefficent: "Коэффициент"
    },
    wallet: {
        noAdress: 'Адрес не может быть пустым',
        submit: 'Заявка на вывод средств подана, ожидайте подтверждения...',
        range: 'Введите сумму в пределах диапазона вывода'
    }
};
