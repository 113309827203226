export default {
    main: {
        login: '登录',
        signup: '注册',
        language: '语言',
        toTop: '回到顶部',
        licence: '许可',
        profile: '个人资料',
        nodata: '无数据',
        logout: '登出',
        notify: '通知',
        all: '全部',
        system: '系统通知',
        activity: '活动',
        clear: '清除全部',
        more: '加载更多',
        wallet: '钱包',
        deposit: '充值',
        withdraw: '提现',
        trans: '交易记录',
        currency: '充值币种',
        network: '网络',
        address: '充值地址',
        mini: '最低充值金额',
        send: '请仅向此充值地址发送 USDT。币种将在 6 次网络确认后自动到账。不支持智能合约地址（如有疑问请联系我们）。',
        withAddress: '提现地址',
        withEnter: '请输入您的地址',
        amount: '提现金额',
        security: '出于安全考虑，大额或可疑提现可能需要 1-6 小时的审核时间。感谢您的耐心等待！',
        today: '今天',
        week: '本周',
        month: '本月',
        year: '今年',
        myWallet: '我的钱包',
        available: '可用余额',
        fee: '手续费',
        chat: '聊天',
        online: '在线',
        message: '输入消息',
        rules: '规则'
    },
    login: {
        email: "请输入邮箱",
        incorrect: "邮箱格式不正确",
        password: "请输入密码",
        characters: "密码为长度大于6的数字加字母组合",
        account: "账户",
        login: "登录",
        register: "注册",
        userEmail: "你的邮箱",
        enterEmail: "输入你的邮箱",
        userPas: "你的密码",
        enterPas: "输入你的密码",
        forget: "忘记密码？",
        or: "或",
        agree: "我已阅读并同意《条款和条件》。",
        resAndLogin: "注册并登录",
        policy: "此网站受 reCAPTCHA 保护，并且适用 Google 的 <span>隐私政策</span> 和 <span>服务条款</span>."
    },
    profile: {
        password: "请输入新密码",
        long: "密码必须包含至少一个数字、字母或空格，并且长度在6到15个字符之间",
        newPas: "确认密码与新密码不匹配",
        original: "请输入原始密码",
        personal: '个人设置',
        change: '更改昵称和个人信息',
        username: '用户名',
        hide: '隐藏我的昵称',
        private: '私人资料',
        closing: '关闭账户',
        closeDatail: '您可以通过点击下方的“关闭我的账户”按钮来关闭账户。所有数据将被永久删除，无法恢复（包括任何VIP权益）。',
        closeMy: '关闭我的账户',
        social: '社交',
        media: '连接社交媒体',
        connected: '已连接',
        noConnect: '未连接',
        settings: '设置',
        account: '账户通用设置',
        email: '电子邮件',
        verify: '请验证您的电子邮件',
        factor: '双重身份验证',
        enable: '启用双重身份验证以保护您的账户免遭未经授权的访问。',
        prohibit: '禁止',
        pas: '密码',
        current: '当前密码',
        please: '请输入当前密码',
        newPassword: '新密码',
        paleseNew: '请输入新密码',
        repeat: '重复密码',
        pleaseCurrent: '请输入当前密码',
        changePas: '更改密码',
        sessions: '会话',
        devices: '检查您的设备',
        history: '游戏历史',
        referrals: '推荐人',
        VIP: 'VIP 计划',
        check: '检查您的所有交易',
        allTrans: '所有交易',
        withdrawal: '提款',
        timeframe: '时间范围',
        today: '今天',
        deposits: '存款',
        chackGame: '检查您的所有游戏',
        chain: '区块链'
    },
    components: {
        search: "搜索",
        sort: "排序方式：",
        popular: "热门",
        providers: "提供者：",
        new: "最新",
        hot: "热度",
        notice: "暂时没有公告。",
        enable: "启用",
        cybet: "Cybet.com 由 Eskimo Adventures Ltd. 拥有，注册号为 000043034，位于伯利兹安伯格里斯凯岛圣佩德罗海乌贼街。联系方式：support{'@'}cybet.com。由科摩罗联盟安茹昂政府颁发的许可证号为 ALSI-202409034-FI2。Cybet.com 完全遵守赌博运营的监管标准。赌博具有财务风险，可能会上瘾。请负责任地赌博。参与者必须年满 18 岁才能参与。",
        progress: "您的 VIP 进度",
        discover: "通过 CY.GAMES 的专属 VIP 系统，探索终极游戏体验！",
        link: "您的链接",
        share: "邀请每位朋友可获得 $1,000.00 现金奖励，外加高达 25% 的投注佣金。无论他们在赌场或体育博彩中赢或输，您都可以赚取稳定的佣金。立即开始赚取吧！",
        referralLink: "您的推荐链接",
        code: "您的推荐代码",
        yourReferrals: "您的所有推荐",
        receive: "接收",
        game: "所有游戏",
        winnings: "赢得奖金",
        loosing: "输掉的金额",
        wagered: "您的玩家总投注金额",
        earned: "您已赚取",
        nothing: "暂无可领取的奖励",
        join: "加入我们精英玩家的社区，享受最顶级的在线游戏体验。",
        details: "详情",
        unverified: "未验证",
        verified: "邮箱已验证",
        type: '交易类型',
        date: '日期',
        total: '总下注金额',
        methods: '支付方式',
        status: '状态',
        device: '设备',
        location: '位置',
        IP: 'IP地址',
        last: '最后使用时间',
        tableGame: '游戏',
        bet: '下注',
        multiplayer: '多人游戏',
        profit: '利润',
        searchGame: '搜索游戏',
        provider: '供应商',
        ourGames: '我们的游戏',
        allWins: '所有胜利',
        luckyWins: '幸运胜利',
        bigWins: '大胜利',
        latest: '最新下注',
        player: '玩家姓名',
        time: '时间',
        amount: '金额',
        coefficent: '系数'
    },
    wallet: {
        noAdress: '地址不能为空',
        submit: '出款申请已提交,等待确认中...',
        range: '请输入提款范围内的金额数字'
    },
    CYGames: 'CY games',
    PopularGames: 'Popular games',
};
