import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index';
import '@/assets/style/index.scss';
import '@/assets/style/font.scss';
import i18n from '@/locales/i18n';
import { bus } from '@/Utils/bus';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'element-plus/theme-chalk/display.css'; //基于断点的隐藏
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import "@/assets/style/common.scss";
import "@/assets/style/skin.scss";
import "@/assets/style/skinV2.scss";
import "@/assets/style/numerical.scss";
import "@/assets/style/Mobile/mobile.scss";
import "@/assets/style/Mobile/pc.scss";
import "@/assets/style/Mobile/lg.scss";
import BaseButton from '@/components/BaseButton/BaseButton.vue';
import MaskDialog from '@/components/Dialog/MaskDialog.vue';
// import {setRootFontSize} from '@/Utils/setRootFontSize';
// import MessageService from "./plugins/message-plugin";
// import myPlugin from "./plugins/message-plugin";
// setRootFontSize();
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
//注册全局 i18n转换函数
app.config.globalProperties.$t = (val) => {
    return i18n.global.t(val);
};
//注册全局bus
app.config.globalProperties.$bus = bus;
app.component('BaseButton', BaseButton);
app.component('MaskDialog', MaskDialog);
// app.use(MessageService)
app.use(router);
app.use(i18n);
app.use(ElementPlus);
// window.addEventListener('resize', setRootFontSize);
app.mount('#app');
