import { computed, ref } from "vue";
import Storage from '@/Utils/ts/storage';
import storage from '@/Utils/ts/storage';
import { mainContent, userContent, wallet } from "@/Utils/ts/api";
import router from "@/router/index";
import { bus } from "@/Utils/bus";
import { initWebSocket } from '../ts/notificationWs';
import formatPrice from "../ts/formatPrice";
// import {bus} from "@/Utils/bus";
const loginStatus = ref(false);
const userInfo = ref({
    "id": "",
    "userName": "",
    "currentProgress": 0,
    "googleSecret": "",
    "nextProgress": "",
    "progress": "",
    "levelId": "",
    "googleUrl": "",
    "selectedBalanceType": "USD",
    "canGetUpReward": 0,
    "canGetUpRewardAmount": '',
    "imgUrl": "",
    "tell": null,
    "email": "",
    "sharCode": null,
    "gent": null,
    "nickName": null,
    "birth": null,
    "country": null,
    "province": null,
    "region": null,
    "address": null,
    "identityCard": null,
    "identityType": null,
    "payPwd": null,
    "twoAuth": 1,
    "emailAuth": 0,
    "balance": {
        "USDT": 0
    },
    "rate": {},
    "privacy": 0,
    'selectedCoinType': 'USDT'
});
const u = storage.getItem('userInfo');
u ? userInfo.value = { ...userInfo.value, ...JSON.parse(u) } : '';
const changeLoginStatus = async (bol, str, isWs301Init) => {
    loginStatus.value = bol;
    Storage.setItem('loginStatus', String(bol));
    if (str === 'init')
        initWebSocket();
    if (bol) {
        //登录以后
        getUserData(isWs301Init);
        if (str !== 'init')
            initWebSocket();
        bus.emit('getNewsNotify');
    }
    else {
        //退出以后
        if (window.location.hash.indexOf('profile') !== -1) {
            router.push('/');
        }
        Storage.clear();
    }
};
//获取用户数据
const getUserData = (isWs301Init, levelFlag) => {
    // if (!loginStatus.value) return
    // if (userInfo.value) return
    userContent.getMyInfo({ levelFlag: !!levelFlag }, (res) => {
        if (res) {
            res.data.currentProgress = (parseFloat(((res.data.progress * 1) / (res.data.nextProgress * 1)).toFixed(2)) * 100) || 0;
            // userInfo.value = res.data; //直接赋值导致初始对象地址丢失
            userInfo.value = { ...userInfo.value, ...res.data };
            // userInfo.value.selectedBalanceType = 'USDT'
            loginStatus.value = true;
            Storage.setItem('loginStatus', String(true));
            Storage.setItem('userInfo', JSON.stringify({ ...userInfo.value, ...res.data }));
            // !isWs301Init && initWebSocket()
        }
        else {
            changeLoginStatus(false, 'init');
        }
    });
};
const coinType = ref([]);
const legalType = ref([]);
const getCurreny = async () => {
    await wallet.getCurreny({}, (res) => {
        if (!res)
            return;
        coinType.value = res.data.coins;
        legalType.value = res.data.faits;
    });
};
const rateData = ref({}); //虚拟币与法币之间的汇率
const legalData = ref({}); //法币与法币之间的汇率
const getRate = async () => {
    await wallet.rateAll({}, (res) => {
        if (!res)
            return;
        rateData.value = res.data.rate;
        legalData.value = res.data.legalRate.results;
    });
};
//获取当前法币的图标和symbol
const getDefailtImg = computed(() => {
    if (userInfo.value.selectedCoinType && coinType.value.length > 0) {
        const obj = coinType.value.find((item) => item.coin === userInfo.value.selectedCoinType);
        if (obj) {
            return obj.imgUrl;
        }
        return require("@/assets/img/monyimg/1.svg");
    }
    return require("@/assets/img/monyimg/1.svg");
});
const getDefailtSymbol = computed(() => {
    if (userInfo.value.selectedBalanceType && legalType.value.length > 0) {
        const obj = legalType.value.find((item) => item.fiat === userInfo.value.selectedBalanceType);
        if (obj) {
            return obj.symbol;
        }
        return '$';
    }
    return '$';
});
const victuralList = ref([]);
//获取当前能使用的虚拟币
function getVictural() {
    return new Promise((resolve) => {
        const arr = [];
        coinType.value.forEach((item) => {
            const obj = item;
            obj.amount = 0.00;
            Object.values(userInfo.value.balance).forEach((it) => {
                if (it.accountType === item.coin) {
                    obj.amount = it.amount;
                }
            });
            arr.push(obj);
        });
        victuralList.value = arr;
        resolve();
    });
}
//获取游戏分类
const centerHeaderNavList = ref([]);
//当前选中的一级游戏菜单的ID
const centerActive = ref(Storage.getItem('centerActive') || '');
loginStatus.value = Storage.getItem('loginStatus') === 'true';
const liveStatus = ref(Storage.getItem('liveStatus') !== 'false');
//切换聊天室伸缩
const changeLiveStatus = () => {
    const bol = Storage.getItem('liveStatus') !== 'true';
    liveStatus.value = Storage.getItem('liveStatus') === null ? false : bol;
    Storage.setItem('liveStatus', bol);
};
//未读的通知数量
const unReadNumber = ref(0);
//监听游览器窗口变化，获取当前是什么设备
const deviceAdvanced = ref('');
//监听游览器窗口变化，获取当前main的视口宽邸
const mainViewportWidth = ref(0);
//汇率转换
const newgetLegalTenderToRate = (key) => {
    const uD = userInfo.value;
    if (!uD || !uD.balance || !uD.balance[key])
        return '0.00';
    if (!uD || !rateData.value || !rateData.value[key])
        return '0.00';
    const d = uD.balance[key];
    const legalIcon = uD.selectedBalanceType;
    const r = rateData.value[key][legalIcon];
    if (!legalIcon || !r)
        return formatPrice(d.amount);
    return formatPrice(d.amount * r.price);
};
//虚拟币转法币
const victuralToLegal = (amount, victuralCoin = 'USDT', legalCoin = 'USD') => {
    if (!amount) {
        amount = 0.00;
    }
    if (Object.values(rateData.value).length === 0) {
        //表示当前还没有获取到
        return formatPrice(amount);
    }
    if (!loginStatus.value) {
        victuralCoin = 'USDT';
        legalCoin = 'USD';
    }
    const rate = rateData.value[victuralCoin];
    if (!rate)
        return formatPrice(amount);
    const legalRate = rate[legalCoin];
    if (!rate || !legalRate)
        return formatPrice(amount);
    return formatPrice(Number(amount) * legalRate.price);
};
//法币转法币
const legalTenderToLegal = (data) => {
    let num = '0';
    const legal = userInfo.value.selectedBalanceType || "USD"; //法币
    const amount = legalData.value[legal] || ""; //法币之间的汇率
    if (amount) {
        num = formatPrice(amount * data);
    }
    return num || 0;
};
const CygameMap = {
    value: [], // 明确设置类型
};
const getCyGameMap = async () => {
    const data = {
        gameName: "",
        gameProvider: "",
        gameType: "v3oaNkfnZCmWxQ9j",
        pageNum: "1",
        pageSize: "200",
        pages: "",
        sortBy: "",
        total: ""
    };
    await mainContent.getListGame(data, (res) => {
        res.data.records.forEach((item, index) => {
            CygameMap.value[index] = {
                channel: item.channel,
                name: item.name,
                gameCode: item.gameCode,
                gameTypeId: item.gameTypeId,
            };
        });
        Storage.setItem('CygameMap', JSON.stringify(CygameMap.value));
    });
};
export { loginStatus, deviceAdvanced, mainViewportWidth, newgetLegalTenderToRate, getUserData, coinType, unReadNumber, userInfo, changeLoginStatus, changeLiveStatus, liveStatus, centerHeaderNavList, centerActive, 
// changeSideList,
// getSideList,
// sideArray,
// sideChildActiveId,
// changeSideChildActiveId,
legalType, getCurreny, getRate, rateData, legalData, victuralToLegal, getVictural, victuralList, legalTenderToLegal, getDefailtImg, getDefailtSymbol, getCyGameMap, CygameMap };
