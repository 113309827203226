import { changeLoginStatus, loginStatus, userInfo } from "@/Utils/hook/hook";
import { bus } from "@/Utils/bus";
// import {ref} from 'vue'
import MessageService from '@/plugins/message-plugin';
let websocket;
let isMyClose = false;
class HeartbeatWebSocket {
    ws = null;
    initialHeartbeatInterval;
    currentHeartbeatInterval;
    heartbeatTimer = null;
    reconnectInterval;
    reconnectTimer = null;
    isConnected = false;
    url;
    reconnectAttempts = 0;
    constructor(url, heartbeatInterval = 3000, reconnectInterval = 5000) {
        this.url = url;
        this.initialHeartbeatInterval = heartbeatInterval;
        this.currentHeartbeatInterval = heartbeatInterval;
        this.reconnectInterval = reconnectInterval;
        this.connect();
    }
    send(message) {
        if (this.isConnected && this.ws) {
            this.ws.send(message);
        }
        else {
            console.error('WebSocket is not connected. Cannot send message:', message);
        }
    }
    close() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
        this.stopHeartbeat();
        if (this.reconnectTimer !== null) {
            clearTimeout(this.reconnectTimer);
        }
    }
    onMessage(e) {
        // 子类可以重写这个方法来处理收到的消息
        try {
            JSON.parse(e.data);
        }
        catch (e) {
            return;
        }
        const msg = JSON.parse(e.data);
        if (msg.code === 1) {
            // case 1:
            // 心跳
            this.resetHeartbeatInterval();
            // break
            return;
        }
        switch (msg.type) {
            case 200:
                console.log('收到新信息 通知');
                bus.emit('getNewsNotify');
                break;
            case 10:
                //收到聊天信息
                if (!msg.data || !msg.data.text)
                    return;
                bus.emit('getNewChatMessage', msg);
                break;
            case 12:
                //收到聊天信息 - 红包
                if (!msg.data)
                    return;
                bus.emit('getNewChatMessage', msg);
                break;
            case 2:
                //收到在线人数通知
                bus.emit('getOnlineUserNumber', JSON.parse(msg.data.userCount));
                break;
            case 3:
                //余额变动
                console.log(msg.data, typeof msg.data);
                bus.emit('changeUserAmount', msg.data);
                break;
            // case 301:
            //     //登录状态变化 -- 登录
            //     changeLoginStatus(true, '', true)
            //     break
            // case 302:
            //     //登录状态变化 -- 未登录
            //     changeLoginStatus(false, 'init',true)
            //     break
            case 303:
                //登录状态变化 -- 未登录
                changeLoginStatus(false, 'init');
                break;
        }
    }
    connect() {
        websocket && websocketclose();
        this.ws = new WebSocket(this.url);
        this.ws.onopen = () => {
            // console.log('WebSocket connected');
            this.isConnected = true;
            this.reconnectAttempts = 0; // 重置重连次数
            this.currentHeartbeatInterval = this.initialHeartbeatInterval; // 重置心跳间隔时间
            this.startHeartbeat();
        };
        this.ws.onmessage = (event) => {
            // console.log('Received message:', event.data);
            if (event.data === "{\"type\":0}") {
                // console.log('Received heartbeat ping');
                this.resetHeartbeatInterval(); // 重置心跳间隔
            }
            else {
                this.onMessage(event);
            }
        };
        this.ws.onclose = () => {
            console.log('WebSocket 断开连接 onclose事件');
            this.isConnected = false;
            this.stopHeartbeat();
            !isMyClose && this.attemptReconnect();
        };
        this.ws.onerror = (error) => {
            console.error('WebSocket 错误:', error);
            this.ws?.close();
        };
    }
    startHeartbeat() {
        this.stopHeartbeat();
        console.log('startHeartbeat', this.currentHeartbeatInterval);
        this.heartbeatTimer = setInterval(() => {
            this.send(JSON.stringify({
                type: 0,
            }));
        }, this.currentHeartbeatInterval);
    }
    stopHeartbeat() {
        console.log('clear heartbeat');
        if (this.heartbeatTimer !== null) {
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }
    resetHeartbeatInterval() {
        this.currentHeartbeatInterval = this.initialHeartbeatInterval;
    }
    attemptReconnect() {
        if (this.reconnectTimer !== null) {
            clearTimeout(this.reconnectTimer);
        }
        this.reconnectTimer = setTimeout(() => {
            this.reconnectAttempts += 1;
            console.log(`尝试重连 次数:${this.reconnectAttempts} 当前秒：${new Date().getSeconds()} 循环时间：${this.reconnectInterval}`);
            this.connect();
        }, this.reconnectInterval);
    }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initWebSocket = () => {
    websocket && websocketclose();
    setTimeout(() => {
        if (process.env.NODE_ENV === 'development')
            return;
        websocket = new HeartbeatWebSocket(`wss://${window.location.host}/chat`);
        // websocket = new HeartbeatWebSocket(`ws://172.19.26.91:8080/chat`);
        // websocket = new HeartbeatWebSocket('ws://172.19.25.4:8080/chat');
    }, 100);
};
// socket 发送数据
const sendMsg = (data) => {
    try {
        websocket.send(JSON.stringify(data));
    }
    catch (e) {
        MessageService.error('Sending failed, please refresh and try again');
        console.log('websocket发送数据失败:', e);
    }
};
const sendChatMsg = (str) => {
    if (!loginStatus.value) {
        bus.emit("openGlobalDialog", { type: "Login" });
        return;
    }
    if (!websocket) {
        console.log('websocket链接失败');
        // MessageService.error('链接失效')
    }
    sendMsg({
        type: 10,
        data: {
            text: str,
            from: userInfo.value.id,
            fromName: userInfo.value.userName,
            imgUrl: userInfo.value.imgUrl,
        }
    });
};
// socket 断开链接
const websocketclose = () => {
    if (!websocket)
        return;
    isMyClose = true;
    // console.log(123456)
    //
    websocket.close();
    // websocket = false
    // setTimeout(() => {
    //     isMyClose = false
    // }, 100)
};
initWebSocket();
export { initWebSocket, sendChatMsg, websocketclose };
