import { createRouter, createWebHashHistory } from "vue-router";
import { loginStatus, userInfo } from "@/Utils/hook/hook";
import { bus } from "@/Utils/bus";
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('@/Views/HomePage/HomeIndex.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/Views/MainPage/MainIndex.vue')
                // component: () => import('@/Views/Policy/IndexPage.vue')
            },
            {
                path: '/policy',
                name: 'policy',
                component: () => import('@/Views/Policy/IndexPage.vue')
            },
            {
                path: '/TestIndex',
                name: 'TestIndex',
                component: () => import('@/Views/Test/TestIndex.vue'),
                meta: {
                    notKeepAlive: true
                }
            },
            {
                path: '/playGame',
                name: 'playGame',
                component: () => import('@/Views/gameDetail/playGame.vue'),
                meta: {
                    needLogin: true
                }
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('@/Views/Profile/indexPage.vue'),
                meta: {
                    needLogin: true
                }
            }, {
                path: '/SearchGame/:sortBy?/:provider?',
                name: 'SearchGame',
                component: () => import('@/components/SearchGame/SearchGame.vue'),
            }, {
                path: '/Overview',
                name: 'drop',
                component: () => import('@/Views/Overview/OverViewIndex.vue'),
            },
            {
                path: '/VipBonus',
                name: 'VipBonus',
                component: () => import('@/Views/VipBonus/VipBonus.vue'),
                meta: {
                    needLogin: true
                }
            },
            {
                path: '/CyGame/:gameCode',
                name: 'CyGame',
                component: () => import('@/Views/CyGame/CyGame.vue'),
                props: true,
                meta: {
                    needLogin: true,
                    notKeepAlive: true
                }
            },
        ]
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
window.CygameConfiguration = function () {
    return sessionStorage.getItem('dataUrl') || "";
};
router.beforeEach(async (to, from, next) => {
    if (to.path === '/playGame') {
        if (to.query.channel === 'HS-GAME') {
            if (!loginStatus.value && to.meta.needLogin) {
                bus.emit("openGlobalDialog", { type: "Login" });
                console.log(from.fullPath);
                next(from.fullPath);
                return;
            }
            else {
                console.log(to.query);
                const dataUrl = "https://www.hsgame.win/game/" + to.query.gameCode +
                    "/?operator_token=5nCG5tTsASs4dK4SX3NYwASAe7WeQt3S" +
                    "&operator_player_session=" +
                    userInfo.value.id + ',' +
                    to.query.currency +
                    "," +
                    userInfo.value.selectedCoinType +
                    "&game_id=" +
                    to.query.gameCode + '&' +
                    "broswer=" +
                    "pc";
                sessionStorage.setItem('dataUrl', dataUrl);
                window.postMessage(JSON.stringify({ type: "destroyGame" }), "*");
                // 确保使用适当的类型来定义路径
                const gameCode = String(to.query.gameCode);
                next({
                    name: 'CyGame', // 使用路由的名称
                    params: { gameCode } // 将 gameCode 作为 params 传递
                });
                return;
            }
        }
    }
    else {
        window.postMessage(JSON.stringify({ type: "destroyGame" }), "*");
        if (window.unloadScripts) {
            window.unloadScripts();
        }
        next();
    }
    if (!loginStatus.value && to.meta.needLogin) {
        bus.emit("openGlobalDialog", { type: "Login" });
        next({
            path: '/',
            query: { redirect: to.fullPath }
        });
        return;
    }
    // if (to.path === '/' || to.path === '') {
    //     sessionStorage.removeItem('cybet-sideObj');
    // }
    next();
});
export default router;
