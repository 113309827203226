import zh_CN from "./lang/zh_CN";
import en_US from "./lang/en_US";
import ar_AE from "./lang/ar_AE";
import in_IN from "./lang/in_IN";
import fa_IR from "./lang/fa_IR";
import cs_CZ from "./lang/cs_CZ";
import es_ES from "./lang/es_ES";
import fi_FI from "./lang/fi_FI";
import en_PH from "./lang/en_PH";
import fr_FR from "./lang/fr_FR";
import hi_IN from "./lang/hi_IN";
import ja_JP from "./lang/ja_JP";
import ko_KR from "./lang/ko_KR";
import pt_PT from "./lang/pt_PT";
import ru_RU from "./lang/ru_RU";
import sr_SP from "./lang/sr_SP";
import th_TH from "./lang/th_TH";
import vi_VN from "./lang/vi_VN";
import tr_TR from "./lang/tr_TR";
const option = {
    zh_CN,
    en_US,
    ar_AE, //阿拉伯语言
    in_IN, //印度尼西亚语 
    fa_IR, //波斯语 
    cs_CZ, //捷克语
    es_ES, //西班牙 
    fi_FI, //芬兰语
    en_PH, //菲律宾
    fr_FR, //法语
    hi_IN, //印度语
    ja_JP, //日本语
    ko_KR, //韩语
    pt_PT, //葡萄牙语言 
    ru_RU, //俄语
    sr_SP, //塞尔维亚
    th_TH, //泰语
    vi_VN, //越南语
    tr_TR //土耳其语言
};
export default option;
/**
 *
 *
 * 简体中文(中国)	zh-cn
 *
韩文(韩国)	ko-kr
日语(日本)	ja-jp
英语(美国)	en-us
芬兰语(芬兰)	fi-fi
丹麦语(丹麦)	da-dk
希伯来语(以色列)	he-il
荷兰语(荷兰)	nl-nl
葡萄牙语(葡萄牙)	pt-pt
法语(法国)	fr-fr
西班牙语(西班牙)	es-es
德语(德国)	de-de
俄语(俄罗斯)	ru-ru
意大利语(意大利)	it-it
希腊语(希腊)	el-gr
挪威语(挪威)	no-no
匈牙利语(匈牙利)	hu-hu
土耳其语(土耳其)	tr-tr
捷克语(捷克共和国)	cs-cz
斯洛文尼亚语	sl-sl
波兰语(波兰)	pl-pl
瑞典语(瑞典)	sv-se
塞尔维亚语 sr-sr
阿拉伯语  al-al
印度(印度语) hin-di
菲律宾(菲律宾语) fi-pi
波斯语 bo-si
印度尼西亚语 Indonesian ba-in
越南语  ti-vi
泰国(泰语) th-ai
 *
 *
 */ 
