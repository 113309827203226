export default {
    main: {
        login: 'Log in',
        signup: 'Sign up',
        language: 'Language',
        toTop: 'To top',
        licence: 'licence',
        profile: 'Personal Profile',
        nodata: 'No Data',
        logout: 'Log out',
        notify: 'Notifications',
        all: 'All',
        system: 'system notice',
        activity: 'Activities',
        clear: 'clear all',
        more: 'Load more',
        wallet: 'Wallet',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        trans: 'Transactions',
        currency: 'Deposit currency',
        network: 'Network',
        address: 'Deposit adress',
        mini: 'Minimum Deposit',
        send: 'Send only USDT to this deposit address. Coins will be deposited automatically after 6 network confirmations. Smart contract addresses are not supported(Contact us).',
        withAddress: 'Withdrawal address',
        withEnter: 'Enter your address',
        amount: 'Withdrawal amount',
        security: 'For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!',
        today: 'Today',
        week: 'This week',
        month: 'This month',
        year: 'This year',
        myWallet: 'My wallet',
        available: 'Available',
        fee: 'Fee',
        chat: 'Chat',
        online: 'online',
        message: 'Enter message',
        rules: 'Rules',
    },
    login: {
        email: "Please input the email",
        incorrect: "The mailbox format is incorrect",
        password: "Please input the password",
        characters: "The password is a combination of numbers and letters with a length greater than 6",
        account: 'Account',
        login: 'Login',
        register: 'Register',
        userEmail: 'Your e-mail',
        enterEmail: 'Enter your e-mail',
        userPas: 'Your password',
        enterPas: 'Enter your password',
        forget: 'Forgot Password?',
        or: 'OR',
        agree: 'I have read and agree to the Terms & Conditions.',
        resAndLogin: 'Register and Login',
        policy: "This site is protected by reCAPTCHA and the Google <span>Privacy Policy</span> and <span> Terms of Service apply</span> ."
    },
    profile: {
        password: "Please enter a new password",
        long: "Password must contain at least one digit, letter, or whitespace and be between 6-15 characters long",
        newPas: "Confirmation password does not match the new password",
        original: "Please enter the original password",
        personal: 'Personal seetings',
        change: 'Change nickname and your data',
        username: 'Your userName',
        hide: 'Hide my nickname',
        private: 'Private profile',
        closing: 'Closing your account',
        closeDatail: 'You can close your account by clicking on the “Close My Account” button below. All your data will be permanently deleted with no possibility of future recovery (including any VIP benefits).',
        closeMy: 'Close my account',
        social: 'Social',
        media: 'Connect social media',
        connected: 'Connected',
        noConnect: "not connected",
        settings: 'Settings',
        account: 'General settings of your account',
        email: 'E-mail',
        verify: 'Please verify your e-mail',
        factor: 'Two-factor authentication',
        enable: 'Enable Two-factor to protect your account from unauthorized access.',
        prohibit: 'Prohibit',
        pas: 'Password',
        current: 'Your current password',
        please: 'Please input your current password',
        newPassword: 'New password',
        paleseNew: 'Please input your new password',
        repeat: 'Repeat password',
        pleaseCurrent: 'Please input your current password',
        changePas: 'Change password',
        sessions: 'Sessions',
        devices: 'Check your devices',
        history: 'Game history',
        referrals: 'Referrals',
        VIP: 'VIP Program',
        check: 'Check all your transactions',
        allTrans: 'All transactions',
        withdrawal: 'Withdrawals',
        timeframe: 'Timeframe',
        today: 'This today',
        deposits: 'Deposits',
        chackGame: 'Check all your games',
        chain: 'Chain'
    },
    components: {
        search: 'search',
        sort: 'sort By:',
        popular: 'Popular',
        providers: 'providers:',
        new: 'New',
        hot: 'Hot',
        notice: "No messages at this time",
        enable: 'Enable',
        cybet: "Cybet.com is owned by Eskimo Adventures Ltd., Registration No. 000043034, located at Sea Urchin Street, San Pedro, Ambergris Caye, Belize. Contact: support{'@'}cybet.com. Licensed by the Government of Anjouan, Union of Comoros under License No. ALSI-202409034-FI2. Cybet.com is fully compliant with regulatory standards for gaming operations. Gambling involves financial risk and can be addictive. Please gamble responsibly. Participants must be 18+ to play.",
        progress: 'Your VIP Progress',
        discover: "Discover the ultimate gaming experience with CY.GAMES's exclusive VIP system!",
        link: 'Your link',
        share: "Get $1,000.00 for each friend you invite, plus a up to 25% commission on their wagers. Enjoy consistent commissions, whether they win or lose, in our Casino and Sportsbook. Start earning now!",
        referralLink: 'Your referral link',
        code: 'Your referral code',
        yourReferrals: 'All your referrals',
        receive: 'Receive',
        game: 'All games',
        winnings: 'Winnings',
        loosing: 'Loosing',
        wagered: 'Your players have wagered',
        earned: 'You have earned',
        nothing: 'Nothing to claim yet',
        join: 'Join our community of elite players and enjoy the best that online gaming has to offer.',
        details: 'Details',
        unverified: 'unverified',
        verified: 'Email has been verified',
        type: 'Type of transaction',
        date: 'Date',
        total: 'Total wagered',
        methods: 'Payment methods',
        status: 'Status',
        device: 'Device',
        location: 'Location',
        IP: 'IP Address',
        last: 'Last Used',
        tableGame: 'Game',
        bet: 'Bet',
        multiplayer: 'Multiplayer',
        profit: 'Profit',
        searchGame: 'Search for game',
        provider: 'Providers',
        ourGames: 'Our games',
        allWins: 'All wins',
        luckyWins: 'Lucky wins',
        bigWins: 'Big wins',
        latest: 'Latest bets',
        player: 'PLAYER NAME',
        time: 'TIME',
        amount: 'AMOUNT',
        coefficent: 'COEFFICIENT'
    },
    wallet: {
        noAddress: 'Address cannot be empty',
        submit: 'Withdrawal request has been submitted, waiting for confirmation...',
        range: 'Please enter a numeric amount within the withdrawal range'
    }
};
