export default {
    main: {
        login: 'Iniciar sesión',
        signup: 'Registrarse',
        language: 'Idioma',
        toTop: 'Ir arriba',
        licence: 'Licencia',
        profile: 'Perfil Personal',
        nodata: 'Sin datos',
        logout: 'Cerrar sesión',
        notify: 'Notificaciones',
        all: 'Todo',
        system: 'Aviso del sistema',
        activity: 'Actividades',
        clear: 'Borrar todo',
        more: 'Cargar más',
        wallet: 'Billetera',
        deposit: 'Depósito',
        withdraw: 'Retiro',
        trans: 'Transacciones',
        currency: 'Moneda de depósito',
        network: 'Red',
        address: 'Dirección de depósito',
        mini: 'Depósito mínimo',
        send: 'Envía solo USDT a esta dirección de depósito. Las monedas se depositarán automáticamente después de 6 confirmaciones de red. Las direcciones de contratos inteligentes no son compatibles (Contáctenos).',
        withAddress: 'Dirección de retiro',
        withEnter: 'Ingresa tu dirección',
        amount: 'Cantidad de retiro',
        security: 'Por razones de seguridad, los retiros grandes o sospechosos pueden tardar entre 1 y 6 horas para el proceso de auditoría. ¡Agradecemos tu paciencia!',
        today: 'Hoy',
        week: 'Esta semana',
        month: 'Este mes',
        year: 'Este año',
        myWallet: 'Mi billetera',
        available: 'Disponible',
        fee: 'Comisión',
        chat: 'Chat',
        online: 'En línea',
        message: 'Ingresar mensaje',
        rules: 'Reglas'
    },
    login: {
        email: "Por favor, introduzca el correo electrónico",
        incorrect: "El formato del correo electrónico es incorrecto",
        password: "Por favor, introduzca la contraseña",
        characters: "La contraseña es una combinación de números y letras de más de 6 carácteres",
        account: "Cuenta",
        login: "Iniciar sesión",
        register: "Registrarse",
        userEmail: "Tu correo electrónico",
        enterEmail: "Introduce tu correo electrónico",
        userPas: "Tu contraseña",
        enterPas: "Introduce tu contraseña",
        forget: "¿Olvidaste tu contraseña?",
        or: "o",
        agree: "He leído y acepto los Términos y Condiciones.",
        resAndLogin: "Regístrate e inicia sesión",
        policy: "Este sitio está protegido por reCAPTCHA y se aplican la <span>Política de privacidad</span> y los <span> Términos de servicio</span> de Google."
    },
    profile: {
        password: "Por favor, introduzca una nueva contraseña",
        long: "La contraseña debe contener al menos un dígito, letra o espacio en blanco y tener entre 6 y 15 caracteres de longitud",
        newPas: "La contraseña de confirmación no coincide con la nueva contraseña",
        original: "Por favor, introduzca la contraseña original",
        personal: 'Configuración personal',
        change: 'Cambia el apodo y tus datos',
        username: 'Tu nombre de usuario',
        hide: 'Ocultar mi apodo',
        private: 'Perfil privado',
        closing: 'Cierre de cuenta',
        closeDatail: 'Puedes cerrar tu cuenta haciendo clic en el botón "Cerrar mi cuenta" a continuación. Todos tus datos serán eliminados de forma permanente sin posibilidad de recuperación futura (incluidos los beneficios VIP).',
        closeMy: 'Cerrar mi cuenta',
        social: 'Social',
        media: 'Conectar redes sociales',
        connected: 'Conectado',
        noConnect: 'No conectado',
        settings: 'Configuración',
        account: 'Configuración general de tu cuenta',
        email: 'Correo electrónico',
        verify: 'Por favor, verifica tu correo electrónico',
        factor: 'Autenticación de dos factores',
        enable: 'Habilitar autenticación de dos factores para proteger tu cuenta de accesos no autorizados.',
        prohibit: 'Prohibir',
        pas: 'Contraseña',
        current: 'Tu contraseña actual',
        please: 'Por favor, introduce tu contraseña actual',
        newPassword: 'Nueva contraseña',
        paleseNew: 'Por favor, introduce tu nueva contraseña',
        repeat: 'Repetir contraseña',
        pleaseCurrent: 'Por favor, introduce tu contraseña actual',
        changePas: 'Cambiar contraseña',
        sessions: 'Sesiones',
        devices: 'Revisa tus dispositivos',
        history: 'Historial de juegos',
        referrals: 'Referidos',
        VIP: 'Programa VIP',
        check: 'Revisa todas tus transacciones',
        allTrans: 'Todas las transacciones',
        withdrawal: 'Retiros',
        timeframe: 'Periodo de tiempo',
        today: 'Hoy',
        deposits: 'Depósitos',
        chackGame: 'Revisa todos tus juegos',
        chain: 'Cadena'
    },
    components: {
        search: "Buscar",
        sort: "Ordenar por:",
        popular: "Popular",
        providers: "Proveedores:",
        new: "Nuevo",
        hot: "Caliente",
        notice: "Aún no hay notificaciones.",
        enable: "Habilitar",
        cybet: "Cybet.com es propiedad de Eskimo Adventures Ltd., con número de registro 000043034, ubicada en Sea Urchin Street, San Pedro, Ambergris Caye, Belice. Contacto: support{'@'}cybet.com. Licenciada por el Gobierno de Anjouan, Unión de Comoras, bajo la licencia número ALSI-202409034-FI2. Cybet.com cumple plenamente con los estándares regulatorios para las operaciones de juego. El juego conlleva riesgos financieros y puede ser adictivo. Juegue de manera responsable. Los participantes deben tener 18 años o más para jugar.",
        progress: "Tu progreso VIP",
        discover: "¡Descubre la experiencia de juego definitiva con el exclusivo sistema VIP de CY.GAMES!",
        link: "Tu enlace",
        share: "Obtén $1,000.00 por cada amigo que invites, más hasta un 25% de comisión sobre sus apuestas. Disfruta de comisiones constantes, ya sea que ganen o pierdan, en nuestro Casino y Apuestas Deportivas. ¡Empieza a ganar ahora!",
        referralLink: "Tu enlace de referencia",
        code: "Tu código de referencia",
        yourReferrals: "Todas tus referencias",
        receive: "Recibir",
        game: "Todos los juegos",
        winnings: "Ganancias",
        loosing: "Pérdidas",
        wagered: "Tus jugadores han apostado",
        earned: "Has ganado",
        nothing: "Nada para reclamar aún",
        join: "Únete a nuestra comunidad de jugadores élite y disfruta de lo mejor que el juego en línea tiene para ofrecer.",
        details: "Detalles",
        unverified: "no verificado",
        verified: "Correo electrónico verificado",
        type: "Tipo de transacción",
        date: "Fecha",
        total: "Total apostado",
        methods: "Métodos de pago",
        status: "Estado",
        device: "Dispositivo",
        location: "Ubicación",
        IP: "Dirección IP",
        last: "Último uso",
        tableGame: "Juego",
        bet: "Apuesta",
        multiplayer: "Multijugador",
        profit: "Ganancia",
        searchGame: "Buscar juego",
        provider: "Proveedores",
        ourGames: "Nuestros juegos",
        allWins: "Todas las victorias",
        luckyWins: "Victorias afortunadas",
        bigWins: "Grandes victorias",
        latest: "Últimas apuestas",
        player: "NOMBRE DEL JUGADOR",
        time: "HORA",
        amount: "CANTIDAD",
        coefficent: "COEFICIENTE"
    },
    wallet: {
        noAddress: 'La dirección no puede estar vacía',
        submit: 'La solicitud de retiro ha sido enviada, esperando confirmación...',
        range: 'Por favor, ingrese un monto numérico dentro del rango de retiro'
    }
};
