export default {
    main: {
        login: 'Mag-log in',
        signup: 'Mag-sign up',
        language: 'Wika',
        toTop: 'Sa itaas',
        licence: 'Lisensya',
        profile: 'Personal na Profile',
        nodata: 'Walang Data',
        logout: 'Mag-logout',
        notify: 'Mga Abiso',
        all: 'Lahat',
        system: 'Abiso ng sistema',
        activity: 'Mga Gawain',
        clear: 'I-clear lahat',
        more: 'Mag-load ng higit pa',
        wallet: 'Pitaka',
        deposit: 'Deposito',
        withdraw: 'Pag-withdraw',
        trans: 'Mga Transaksyon',
        currency: 'Deposito ng currency',
        network: 'Network',
        address: 'Deposit address',
        mini: 'Pinakamababang deposito',
        send: 'Magpadala lamang ng USDT sa address na ito. Ang coins ay awtomatikong ide-deposito matapos ang 6 na kumpirmasyon ng network. Ang mga smart contract address ay hindi suportado (Makipag-ugnayan sa amin).',
        withAddress: 'Withdrawal address',
        withEnter: 'Ilagay ang iyong address',
        amount: 'Halaga ng withdrawal',
        security: 'Para sa seguridad, ang malalaking o kahina-hinalang withdrawal ay maaaring tumagal ng 1-6 na oras para sa proseso ng audit. Salamat sa iyong pasensya!',
        today: 'Ngayon',
        week: 'Ngayong linggo',
        month: 'Ngayong buwan',
        year: 'Ngayong taon',
        myWallet: 'Aking pitaka',
        available: 'Magagamit',
        fee: 'Bayad',
        chat: 'Chat',
        online: 'Online',
        message: 'Ilagay ang mensahe',
        rules: 'Mga Patakaran'
    },
    login: {
        email: "Paki-input ang email",
        incorrect: "Mali ang format ng email",
        password: "Paki-input ang password",
        characters: "Ang password ay isang kumbinasyon ng mga numero at mga titik na may haba na higit sa 6",
        account: "Account",
        login: "Mag-login",
        register: "Magrehistro",
        userEmail: "Iyong email",
        enterEmail: "Ilagay ang iyong email",
        userPas: "Iyong password",
        enterPas: "Ilagay ang iyong password",
        forget: "Nakalimutan ang password?",
        or: "o",
        agree: "Nabasa ko at sumasang-ayon ako sa Mga Tuntunin at Kundisyon.",
        resAndLogin: "Magrehistro at mag-login",
        policy: "Ang site na ito ay protektado ng reCAPTCHA at ang <span>Patakaran sa Privacy</span> at <span>Mga Tuntunin ng Serbisyo</span> ng Google ay naaangkop."
    },
    profile: {
        password: "Pakilagay ang bagong password",
        long: "Ang password ay dapat mayroong hindi bababa sa isang numero, letra, o espasyo at dapat may haba na 6-15 na karakter",
        newPas: "Ang kumpirmasyon ng password ay hindi tugma sa bagong password",
        original: "Pakilagay ang orihinal na password",
        personal: 'Personal na mga Setting',
        change: 'Palitan ang palayaw at iyong mga data',
        username: 'Iyong Username',
        hide: 'Itago ang aking palayaw',
        private: 'Pribadong profile',
        closing: 'Pagsara ng iyong account',
        closeDatail: 'Maaari mong isara ang iyong account sa pamamagitan ng pag-click sa "Isara ang Aking Account" na button sa ibaba. Ang lahat ng iyong data ay permanenteng mabubura at hindi na maaaring mabawi sa hinaharap (kasama ang anumang benepisyong VIP).',
        closeMy: 'Isara ang aking account',
        social: 'Social',
        media: 'Ikonekta ang social media',
        connected: 'Nakakonekta',
        noConnect: 'hindi nakakonekta',
        settings: 'Mga Setting',
        account: 'Pangkalahatang mga setting ng iyong account',
        email: 'E-mail',
        verify: 'Pakibisita ang iyong e-mail',
        factor: 'Dalawang-hakbang na pagpapatotoo',
        enable: 'Paganahin ang Dalawang-hakbang na pagpapatotoo upang protektahan ang iyong account mula sa hindi awtorisadong pag-access.',
        prohibit: 'Ipinagbawal',
        pas: 'Password',
        current: 'Kasulukuyang password mo',
        please: 'Pakipasok ang iyong kasalukuyang password',
        newPassword: 'Bagong password',
        paleseNew: 'Pakipasok ang iyong bagong password',
        repeat: 'Ulitin ang password',
        pleaseCurrent: 'Pakipasok ang iyong kasalukuyang password',
        changePas: 'Palitan ang password',
        sessions: 'Mga session',
        devices: 'Suriin ang iyong mga device',
        history: 'Kasaysayan ng laro',
        referrals: 'Mga Rekomendasyon',
        VIP: 'Programa ng VIP',
        check: 'Suriin ang lahat ng iyong mga transaksyon',
        allTrans: 'Lahat ng transaksyon',
        withdrawal: 'Mga Withdrawal',
        timeframe: 'Takdang oras',
        today: 'Ngayon',
        deposits: 'Mga Deposito',
        chackGame: 'Suriin ang lahat ng iyong mga laro',
        chain: 'Kadena'
    },
    components: {
        search: "Maghanap",
        sort: "Pag-uri ayon sa:",
        popular: "Sikat",
        providers: "Mga Tagapagbigay:",
        new: "Bago",
        hot: "Mainit",
        notice: "Wala pang abiso.",
        enable: "Paganahin",
        cybet: "Cybet.com is owned by Eskimo Adventures Ltd., Registration No. 000043034, located at Sea Urchin Street, San Pedro, Ambergris Caye, Belize. Contact: support{'@'}cybet.com. Licensed by the Government of Anjouan, Union of Comoros under License No. ALSI-202409034-FI2. Cybet.com is fully compliant with regulatory standards for gaming operations. Gambling involves financial risk and can be addictive. Please gamble responsibly. Participants must be 18+ to play.",
        progress: "Ang iyong VIP Pag-usad",
        discover: "Tuklasin ang pinakamahusay na karanasan sa paglalaro sa pamamagitan ng eksklusibong sistema ng VIP ng CY.GAMES!",
        link: "Ang iyong link",
        share: "Kumuha ng $1,000.00 para sa bawat kaibigan na iimbitahan mo, kasama ang hanggang 25% na komisyon sa kanilang mga pustahan. Tangkilikin ang tuluy-tuloy na komisyon, manalo man sila o matalo, sa aming Casino at Sportsbook. Magsimulang kumita ngayon!",
        referralLink: "Ang iyong referral na link",
        code: "Ang iyong referral na code",
        yourReferrals: "Lahat ng iyong mga referral",
        receive: "Tanggapin",
        game: "Lahat ng mga laro",
        winnings: "Mga Panalo",
        loosing: "Mga Talo",
        wagered: "Ang iyong mga manlalaro ay tumaya na",
        earned: "Kumita ka na",
        nothing: "Wala pang dapat i-claim",
        join: "Sumali sa aming komunidad ng mga elite na manlalaro at tamasahin ang pinakamahusay na maiaalok ng online gaming.",
        details: "Mga Detalye",
        unverified: "hindi pa na-verify",
        verified: "Na-verify na ang Email",
        type: "Uri ng transaksyon",
        date: "Petsa",
        total: "Kabuuang taya",
        methods: "Paraan ng pagbabayad",
        status: "Katayuan",
        device: "Device",
        location: "Lokasyon",
        IP: "IP Address",
        last: "Huling Ginamit",
        tableGame: "Laro",
        bet: "Taya",
        multiplayer: "Maramihang Manlalaro",
        profit: "Kita",
        searchGame: "Maghanap ng laro",
        provider: "Mga Tagapagbigay",
        ourGames: "Aming mga laro",
        allWins: "Lahat ng panalo",
        luckyWins: "Masuwerteng panalo",
        bigWins: "Malalaking panalo",
        latest: "Pinakabagong taya",
        player: "PANGALAN NG MANLALARO",
        time: "ORAS",
        amount: "HALAGA",
        coefficent: "KOSYENT"
    },
    wallet: {
        noAdress: 'Ang address ay hindi maaaring walang laman',
        submit: 'Ang aplikasyon para sa pag-withdraw ay naisumite, naghihintay ng kumpirmasyon...',
        range: 'Mangyaring ipasok ang halaga sa loob ng saklaw ng pag-withdraw'
    }
};
