export default {
    main: {
        login: 'ログイン',
        signup: 'サインアップ',
        language: '言語',
        toTop: 'トップへ',
        licence: 'ライセンス',
        profile: '個人プロフィール',
        nodata: 'データなし',
        logout: 'ログアウト',
        notify: '通知',
        all: 'すべて',
        system: 'システム通知',
        activity: 'アクティビティ',
        clear: 'すべてクリア',
        more: 'もっと読み込む',
        wallet: 'ウォレット',
        deposit: '入金',
        withdraw: '出金',
        trans: '取引履歴',
        currency: '入金通貨',
        network: 'ネットワーク',
        address: '入金アドレス',
        mini: '最低入金額',
        send: 'この入金アドレスにはUSDTのみを送金してください。コインはネットワークの6回の確認後に自動的に入金されます。スマートコントラクトアドレスはサポートされていません（お問い合わせください）。',
        withAddress: '出金アドレス',
        withEnter: 'アドレスを入力してください',
        amount: '出金額',
        security: 'セキュリティ上の理由から、大量または疑わしい出金は監査に1〜6時間かかる場合があります。ご理解いただきありがとうございます！',
        today: '今日',
        week: '今週',
        month: '今月',
        year: '今年',
        myWallet: '私のウォレット',
        available: '利用可能',
        fee: '手数料',
        chat: 'チャット',
        online: 'オンライン',
        message: 'メッセージを入力',
        rules: 'ルール'
    },
    login: {
        email: "メールアドレスを入力してください",
        incorrect: "メールアドレスの形式が正しくありません",
        password: "パスワードを入力してください",
        characters: "パスワードは6文字以上の数字とアルファベットの組み合わせです",
        account: "アカウント",
        login: "ログイン",
        register: "登録",
        userEmail: "あなたのメールアドレス",
        enterEmail: "メールアドレスを入力してください",
        userPas: "あなたのパスワード",
        enterPas: "パスワードを入力してください",
        forget: "パスワードを忘れましたか？",
        or: "または",
        agree: "私は利用規約を読み、同意します。",
        resAndLogin: "登録してログイン",
        policy: "このサイトは reCAPTCHA によって保護されており、Google の <span>プライバシーポリシー</span> と <span>利用規約</span> が適用されます."
    },
    profile: {
        password: "新しいパスワードを入力してください",
        long: "パスワードは少なくとも1つの数字、文字、または空白を含み、6〜15文字の長さである必要があります",
        newPas: "確認パスワードが新しいパスワードと一致しません",
        original: "元のパスワードを入力してください",
        personal: '個人設定',
        change: 'ニックネームとデータの変更',
        username: 'ユーザー名',
        hide: 'ニックネームを隠す',
        private: 'プライベートプロフィール',
        closing: 'アカウントの閉鎖',
        closeDatail: '以下の「アカウントを閉じる」ボタンをクリックすることでアカウントを閉じることができます。すべてのデータは永久に削除され、将来的な復元は不可能です（VIP特典も含まれます）。',
        closeMy: 'アカウントを閉じる',
        social: 'ソーシャル',
        media: 'ソーシャルメディアを接続',
        connected: '接続済み',
        noConnect: '未接続',
        settings: '設定',
        account: 'アカウントの一般設定',
        email: 'メールアドレス',
        verify: 'メールアドレスを確認してください',
        factor: '二段階認証',
        enable: '不正アクセスからアカウントを保護するために、二段階認証を有効にしてください。',
        prohibit: '禁止',
        pas: 'パスワード',
        current: '現在のパスワード',
        please: '現在のパスワードを入力してください',
        newPassword: '新しいパスワード',
        paleseNew: '新しいパスワードを入力してください',
        repeat: 'パスワードを再入力',
        pleaseCurrent: '現在のパスワードを入力してください',
        changePas: 'パスワードを変更する',
        sessions: 'セッション',
        devices: 'デバイスを確認する',
        history: 'ゲーム履歴',
        referrals: 'リファーラル',
        VIP: 'VIPプログラム',
        check: 'すべての取引を確認する',
        allTrans: 'すべての取引',
        withdrawal: '引き出し',
        timeframe: '期間',
        today: '今日',
        deposits: '入金',
        chackGame: 'すべてのゲームを確認する',
        chain: 'チェーン'
    },
    components: {
        search: "検索",
        sort: "並び替え:",
        popular: "人気",
        providers: "プロバイダー:",
        new: "新着",
        hot: "ホット",
        notice: "まだ通知はありません。",
        enable: "有効にする",
        cybet: "Cybet.comはEskimo Adventures Ltd.が所有しており、登録番号は000043034、所在地はSea Urchin Street、San Pedro、Ambergris Caye、Belizeです。連絡先：support{'@'}cybet.com。Comoros連邦Anjouan政府から、ライセンス番号ALSI-202409034-FI2でライセンスを受けています。Cybet.comは、ゲーム運営に関する規制基準を完全に遵守しています。ギャンブルには財務リスクが伴い、依存症になる可能性があります。責任を持ってギャンブルを行ってください。参加者は18歳以上である必要があります。",
        progress: "あなたのVIP進捗",
        discover: "CY.GAMESの独占VIPシステムで究極のゲーミング体験を発見しましょう！",
        link: "あなたのリンク",
        share: "招待した友達1人につき1,000.00ドルを獲得し、彼らの賭けの最大25％のコミッションも手に入れましょう。カジノとスポーツブックで、彼らが勝とうが負けようが安定したコミッションを楽しんでください。今すぐ稼ぎ始めましょう！",
        referralLink: "あなたの紹介リンク",
        code: "あなたの紹介コード",
        yourReferrals: "あなたの全ての紹介",
        receive: "受け取る",
        game: "すべてのゲーム",
        winnings: "勝利金",
        loosing: "損失",
        wagered: "あなたのプレイヤーが賭けた金額",
        earned: "あなたが稼いだ金額",
        nothing: "まだ請求するものはありません",
        join: "私たちのエリートゲーマーコミュニティに参加して、オンラインゲームの最高をお楽しみください。",
        details: "詳細",
        unverified: "未確認",
        verified: "メール確認済み",
        type: "取引の種類",
        date: "日付",
        total: "合計賭け金",
        methods: "支払い方法",
        status: "ステータス",
        device: "デバイス",
        location: "場所",
        IP: "IPアドレス",
        last: "最終使用",
        tableGame: "ゲーム",
        bet: "ベット",
        multiplayer: "マルチプレイヤー",
        profit: "利益",
        searchGame: "ゲームを検索",
        provider: "プロバイダー",
        ourGames: "私たちのゲーム",
        allWins: "すべての勝利",
        luckyWins: "ラッキー勝利",
        bigWins: "大勝利",
        latest: "最新のベット",
        player: "プレイヤー名",
        time: "時間",
        amount: "金額",
        coefficent: "係数"
    },
    wallet: {
        noAdress: 'アドレスは空にできません',
        submit: '出金申請が提出されました。確認をお待ちください...',
        range: '出金範囲内の金額を入力してください'
    }
};
