<template>
  <div>

    <!--        <router-view v-slot="{ Component }" :key="$route.fullPath">-->
    <!--          <component :is="Component"/>-->
    <!--        </router-view>-->
    <div style="height: 100vh">
      <router-view v-slot="{ Component }" >
        <keep-alive >
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script setup lang="ts">



</script>

<style lang="scss">
</style>
