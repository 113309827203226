const localKey = 'cybet-';
const isLocalStorageKey = ['InternationalConfig', 'InternationalSysConfig', 'language', 'InternationalLevelConfig', 'InternationalPrivilegeConfig'];
const Storage = {
    setItem(key, value) {
        if (isLocalStorageKey.indexOf(key) === -1) {
            sessionStorage.setItem(localKey + key, value);
            return;
        }
        localStorage.setItem(localKey + key, value);
    },
    getItem(key) {
        if (isLocalStorageKey.indexOf(key) === -1) {
            return sessionStorage.getItem(localKey + key);
        }
        const k = localKey + key;
        return localStorage.getItem(k);
    },
    removeItem(key) {
        if (isLocalStorageKey.indexOf(key) === -1) {
            sessionStorage.removeItem(localKey + key);
            return;
        }
        localStorage.removeItem(localKey + key);
    },
    clear() {
        for (let i = 0; i < localStorage.length; i++) {
            const k = localStorage.key(i);
            if (isLocalStorageKey.indexOf(localKey + k) > -1) {
                localStorage.removeItem(localKey + k);
            }
        }
        for (let i = 0; i < sessionStorage.length; i++) {
            const k = sessionStorage.key(i);
            if (k !== 'cybet-sideObj') {
                if (k) {
                    //除了侧边栏对象之外，其他都清除掉
                    sessionStorage.removeItem(k);
                }
            }
        }
        // sessionStorage.clear()
    }
};
export default Storage;
