export default {
    main: {
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        language: 'Ngôn ngữ',
        toTop: 'Lên đầu trang',
        licence: 'Giấy phép',
        profile: 'Hồ sơ cá nhân',
        nodata: 'Không có dữ liệu',
        logout: 'Đăng xuất',
        notify: 'Thông báo',
        all: 'Tất cả',
        system: 'Thông báo hệ thống',
        activity: 'Hoạt động',
        clear: 'Xóa tất cả',
        more: 'Tải thêm',
        wallet: 'Ví',
        deposit: 'Nạp tiền',
        withdraw: 'Rút tiền',
        trans: 'Giao dịch',
        currency: 'Tiền tệ nạp',
        network: 'Mạng lưới',
        address: 'Địa chỉ nạp tiền',
        mini: 'Nạp tiền tối thiểu',
        send: 'Chỉ gửi USDT vào địa chỉ nạp này. Tiền sẽ tự động được nạp sau 6 lần xác nhận mạng. Không hỗ trợ địa chỉ hợp đồng thông minh (Liên hệ với chúng tôi).',
        withAddress: 'Địa chỉ rút tiền',
        withEnter: 'Nhập địa chỉ của bạn',
        amount: 'Số tiền rút',
        security: 'Vì lý do bảo mật, rút tiền lớn hoặc đáng ngờ có thể mất 1-6 giờ để xử lý kiểm tra. Cảm ơn sự kiên nhẫn của bạn!',
        today: 'Hôm nay',
        week: 'Tuần này',
        month: 'Tháng này',
        year: 'Năm nay',
        myWallet: 'Ví của tôi',
        available: 'Sẵn có',
        fee: 'Phí',
        chat: 'Trò chuyện',
        online: 'Trực tuyến',
        message: 'Nhập tin nhắn',
        rules: 'Quy tắc'
    },
    login: {
        email: "Vui lòng nhập email",
        incorrect: "Định dạng email không chính xác",
        password: "Vui lòng nhập mật khẩu",
        characters: "Mật khẩu là một sự kết hợp của các chữ số và chữ cái có độ dài lớn hơn 6",
        account: "Tài khoản",
        login: "Đăng nhập",
        register: "Đăng ký",
        userEmail: "Email của bạn",
        enterEmail: "Nhập email của bạn",
        userPas: "Mật khẩu của bạn",
        enterPas: "Nhập mật khẩu của bạn",
        forget: "Quên mật khẩu?",
        or: "hoặc",
        agree: "Tôi đã đọc và đồng ý với Điều khoản và Điều kiện.",
        resAndLogin: "Đăng ký và đăng nhập",
        policy: "Trang web này được bảo vệ bởi reCAPTCHA và <span>Chính sách quyền riêng tư</span> cũng như <span>Điều khoản dịch vụ</span> của Google được áp dụng."
    },
    profile: {
        password: "Vui lòng nhập mật khẩu mới",
        long: "Mật khẩu phải chứa ít nhất một chữ số, chữ cái hoặc khoảng trắng và dài từ 6-15 ký tự",
        newPas: "Mật khẩu xác nhận không khớp với mật khẩu mới",
        original: "Vui lòng nhập mật khẩu gốc",
        personal: 'Cài đặt cá nhân',
        change: 'Thay đổi biệt danh và dữ liệu của bạn',
        username: 'Tên người dùng của bạn',
        hide: 'Ẩn biệt danh của tôi',
        private: 'Hồ sơ riêng tư',
        closing: 'Đóng tài khoản của bạn',
        closeDatail: 'Bạn có thể đóng tài khoản của mình bằng cách nhấp vào nút "Đóng Tài Khoản Của Tôi" bên dưới. Tất cả dữ liệu của bạn sẽ bị xóa vĩnh viễn và không thể khôi phục trong tương lai (bao gồm bất kỳ quyền lợi VIP nào).',
        closeMy: 'Đóng tài khoản của tôi',
        social: 'Mạng xã hội',
        media: 'Kết nối mạng xã hội',
        connected: 'Đã kết nối',
        noConnect: 'chưa kết nối',
        settings: 'Cài đặt',
        account: 'Cài đặt chung của tài khoản',
        email: 'E-mail',
        verify: 'Vui lòng xác minh e-mail của bạn',
        factor: 'Xác thực hai yếu tố',
        enable: 'Kích hoạt xác thực hai yếu tố để bảo vệ tài khoản của bạn khỏi truy cập trái phép.',
        prohibit: 'Cấm',
        pas: 'Mật khẩu',
        current: 'Mật khẩu hiện tại của bạn',
        please: 'Vui lòng nhập mật khẩu hiện tại của bạn',
        newPassword: 'Mật khẩu mới',
        paleseNew: 'Vui lòng nhập mật khẩu mới của bạn',
        repeat: 'Nhập lại mật khẩu',
        pleaseCurrent: 'Vui lòng nhập mật khẩu hiện tại của bạn',
        changePas: 'Thay đổi mật khẩu',
        sessions: 'Phiên đăng nhập',
        devices: 'Kiểm tra thiết bị của bạn',
        history: 'Lịch sử trò chơi',
        referrals: 'Giới thiệu',
        VIP: 'Chương trình VIP',
        check: 'Kiểm tra tất cả các giao dịch của bạn',
        allTrans: 'Tất cả giao dịch',
        withdrawal: 'Rút tiền',
        timeframe: 'Khung thời gian',
        today: 'Hôm nay',
        deposits: 'Tiền gửi',
        chackGame: 'Kiểm tra tất cả trò chơi của bạn',
        chain: 'Chuỗi'
    },
    components: {
        search: "Tìm kiếm",
        sort: "Sắp xếp theo:",
        popular: "Phổ biến",
        providers: "Nhà cung cấp:",
        new: "Mới",
        hot: "Hot",
        notice: "Chưa có thông báo.",
        enable: "Kích hoạt",
        cybet: "Cybet.com thuộc sở hữu và được điều hành bởi Eskimo Adventures Ltd. Số đăng ký: 000043034, địa chỉ đã đăng ký: Đường Sea Urchin, San Pedro, Ambergris Caye, Belize. Liên hệ với chúng tôi theo địa chỉ support{'@'}cybet.com. Cybet.com được cấp phép và quản lý bởi Chính quyền Đảo tự trị Anjouan, Liên minh Comoros và hoạt động theo Giấy phép số ALSI-202409034-FI2. Cybet.com đã vượt qua mọi quy định về tuân thủ và được pháp luật cho phép tiến hành các hoạt động chơi game cho bất kỳ và tất cả các trò chơi may rủi và cá cược.\n" +
            "Đánh bạc liên quan đến rủi ro tài chính và có thể gây nghiện. Vui lòng đánh bạc có trách nhiệm. Bạn phải từ 18 tuổi trở lên để tham gia các hoạt động đánh bạc trên trang web này.",
        progress: "Tiến độ VIP của bạn",
        discover: "Khám phá trải nghiệm chơi game tối thượng với hệ thống VIP độc quyền của CY.GAMES!",
        link: "Liên kết của bạn",
        share: "Nhận $1,000.00 cho mỗi người bạn mà bạn mời, cùng với hoa hồng lên đến 25% trên cược của họ. Tận hưởng hoa hồng liên tục, dù họ thắng hay thua, tại Sòng bạc và Nhà cái của chúng tôi. Bắt đầu kiếm tiền ngay!",
        referralLink: "Liên kết giới thiệu của bạn",
        code: "Mã giới thiệu của bạn",
        yourReferrals: "Tất cả những người giới thiệu của bạn",
        receive: "Nhận",
        game: "Tất cả trò chơi",
        winnings: "Tiền thắng",
        loosing: "Thua lỗ",
        wagered: "Người chơi của bạn đã đặt cược",
        earned: "Bạn đã kiếm được",
        nothing: "Chưa có gì để yêu cầu",
        join: "Tham gia cộng đồng người chơi ưu tú của chúng tôi và tận hưởng những gì tốt nhất mà trò chơi trực tuyến mang lại.",
        details: "Chi tiết",
        unverified: "chưa xác minh",
        verified: "Email đã được xác minh",
        type: "Loại giao dịch",
        date: "Ngày",
        total: "Tổng số tiền đặt cược",
        methods: "Phương thức thanh toán",
        status: "Trạng thái",
        device: "Thiết bị",
        location: "Vị trí",
        IP: "Địa chỉ IP",
        last: "Lần sử dụng cuối",
        tableGame: "Trò chơi",
        bet: "Cược",
        multiplayer: "Chơi nhiều người",
        profit: "Lợi nhuận",
        searchGame: "Tìm kiếm trò chơi",
        provider: "Nhà cung cấp",
        ourGames: "Trò chơi của chúng tôi",
        allWins: "Tất cả các chiến thắng",
        luckyWins: "Chiến thắng may mắn",
        bigWins: "Chiến thắng lớn",
        latest: "Cược mới nhất",
        player: "TÊN NGƯỜI CHƠI",
        time: "THỜI GIAN",
        amount: "SỐ TIỀN",
        coefficent: "Hệ số"
    },
    wallet: {
        noAdress: 'Địa chỉ không được để trống',
        submit: 'Yêu cầu rút tiền đã được gửi, chờ xác nhận...',
        range: 'Vui lòng nhập số tiền trong phạm vi rút tiền'
    }
};
