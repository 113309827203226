import { createI18n } from "vue-i18n";
import option from './i18nOption';
import { i18nApi } from "@/Utils/ts/api";
import Storage from '@/Utils/ts/storage';
const i18n = createI18n({
    legacy: false, // 使用Composition API，这里必须设置为false
    locale: Storage.getItem('language') || 'en_US', // 默认显示语言
    messages: option,
});
const getI18nHttp = () => {
    i18nApi.getChatList('', (res) => {
        if (!res)
            return;
        Storage.setItem('InternationalConfig', JSON.stringify(res.data));
        for (const i in res.data) {
            i18n.global.mergeLocaleMessage(i, { InternationalConfig: { ...res.data[i] } });
        }
    });
    i18nApi.getListSysConfigs('', (res) => {
        if (!res)
            return;
        Storage.setItem('InternationalSysConfig', JSON.stringify(res.data));
        for (const i in res.data) {
            i18n.global.mergeLocaleMessage(i, { InternationalSysConfig: { ...res.data[i] } });
        }
    });
    i18nApi.levelI18n('', (res) => {
        if (!res)
            return;
        Storage.setItem('InternationalLevelConfig', JSON.stringify(res.data));
        for (const i in res.data) {
            i18n.global.mergeLocaleMessage(i, { InternationalLevelConfig: { ...res.data[i] } });
        }
    });
    // i18nApi.privilegeI18n('', (res: ResponseData) => {
    //     if (!res) return
    //     Storage.setItem('InternationalPrivilegeConfig', JSON.stringify(res.data))
    //     for (const i in res.data) {
    //         i18n.global.mergeLocaleMessage(i, { InternationalPrivilegeConfig: { ...res.data[i] } });
    //     }
    // })
};
// getI18nHttp()
if (!Storage.getItem('InternationalConfig') || Storage.getItem('InternationalConfig') === 'undefined' ||
    !Storage.getItem('InternationalLevelConfig')) {
    getI18nHttp();
}
else {
    const InternationalConfig = JSON.parse(Storage.getItem('InternationalConfig') || "");
    const InternationalSysConfig = JSON.parse(Storage.getItem('InternationalSysConfig') || "");
    const InternationalLevelConfig = JSON.parse(Storage.getItem('InternationalLevelConfig') || "");
    // const InternationalPrivilegeConfig = JSON.parse(Storage.getItem('InternationalPrivilegeConfig') || "")
    for (const i in InternationalConfig) {
        i18n.global.mergeLocaleMessage(i, { InternationalConfig: { ...InternationalConfig[i] } });
    }
    for (const i in InternationalSysConfig) {
        i18n.global.mergeLocaleMessage(i, { InternationalSysConfig: { ...InternationalSysConfig[i] } });
    }
    for (const i in InternationalLevelConfig) {
        i18n.global.mergeLocaleMessage(i, { InternationalLevelConfig: { ...InternationalLevelConfig[i] } });
    }
    // for (const i in InternationalPrivilegeConfig) {
    //     i18n.global.mergeLocaleMessage(i, { InternationalPrivilegeConfig: { ...InternationalPrivilegeConfig[i] } });
    // }
    setTimeout(() => {
        getI18nHttp();
    }, 10000);
}
export default i18n;
