// const formatPrice = (value: string | number) => {
//     if (value === null || value === undefined) return '';
//     // 将数字转换为字符串并去掉多余的零
//     const numStr = value.toString();
//     // 如果是整数，直接返回
//     if (!numStr.includes('.')) return numStr + '.00';
//     // 分割整数部分和小数部分
//     // eslint-disable-next-line prefer-const
//     let [integerPart, decimalPart] = numStr.split('.');
//     // 移除小数部分末尾的零，确保小数部分至少有两位
//     decimalPart = decimalPart.replace(/0+$/, '');
//     // 如果小数部分不足两位，补充零
//     if (decimalPart.length < 2) {
//         decimalPart = decimalPart.padEnd(2, '0');
//         // 拼接返回
//         return integerPart + '.' + decimalPart;
//     } else {
//         return Number(numStr).toFixed(2);
//     }
// }
function formatPrice(num) {
    // 转换为字符串，避免直接操作数字导致精度问题
    if (num === 0 || num === '0' || !num)
        return 0;
    const numStr = num.toString();
    // 检查是否已经是一个数字加小数点加两位小数的格式  
    if (numStr.indexOf('.') === -1) {
        // 整数，直接添加.00  
        return numStr + '.00';
    }
    else {
        // 有小数部分，但需要截取到两位  
        const parts = numStr.split('.');
        const integerPart = parts[0];
        let decimalPart = parts[1];
        // 截取小数部分到两位  
        decimalPart = decimalPart.slice(0, 2);
        // 如果小数部分不足两位，则补0  
        while (decimalPart.length < 2) {
            decimalPart += '0';
        }
        // 重新组合并返回  
        return integerPart + '.' + decimalPart;
    }
}
export default formatPrice;
