export default {
    main: {
        login: 'Přihlásit se',
        signup: 'Zaregistrovat se',
        language: 'Jazyk',
        toTop: 'Nahoru',
        licence: 'Licence',
        profile: 'Osobní profil',
        nodata: 'Žádná data',
        logout: 'Odhlásit se',
        notify: 'Oznámení',
        all: 'Vše',
        system: 'Systémové oznámení',
        activity: 'Aktivity',
        clear: 'Vymazat vše',
        more: 'Načíst více',
        wallet: 'Peněženka',
        deposit: 'Vklad',
        withdraw: 'Výběr',
        trans: 'Transakce',
        currency: 'Měna vkladu',
        network: 'Síť',
        address: 'Adresa pro vklad',
        mini: 'Minimální vklad',
        send: 'Na tuto vkladovou adresu zasílejte pouze USDT. Mince budou automaticky připsány po 6 potvrzeních sítě. Adresy chytrých kontraktů nejsou podporovány (kontaktujte nás).',
        withAddress: 'Adresa pro výběr',
        withEnter: 'Zadejte svou adresu',
        amount: 'Částka výběru',
        security: 'Z bezpečnostních důvodů může u velkých nebo podezřelých výběrů trvat kontrola 1-6 hodin. Děkujeme za vaši trpělivost!',
        today: 'Dnes',
        week: 'Tento týden',
        month: 'Tento měsíc',
        year: 'Tento rok',
        myWallet: 'Moje peněženka',
        available: 'Dostupné',
        fee: 'Poplatek',
        chat: 'Chat',
        online: 'online',
        message: 'Zadejte zprávu',
        rules: 'Pravidla'
    },
    login: {
        email: "Zadejte prosím e-mail",
        incorrect: "Formát e-mailu je nesprávný",
        password: "Zadejte prosím heslo",
        characters: "Heslo je kombinace číslicí a písmen délkou přesahující 6 znaků",
        account: "Účet",
        login: "Přihlášení",
        register: "Registrovat se",
        userEmail: "Váš e-mail",
        enterEmail: "Zadejte svůj e-mail",
        userPas: "Vaše heslo",
        enterPas: "Zadejte své heslo",
        forget: "Zapomněli jste heslo?",
        or: "nebo",
        agree: "Jsem si vědom/a a souhlasím s Podmínkami a pravidly.",
        resAndLogin: "Registrovat se a přihlásit",
        policy: "Tento web je chráněn pomocí reCAPTCHA a platí <span>Zásady ochrany osobních údajů</span> a <span>Podmínky služby</span> společnosti Google."
    },
    profile: {
        password: "Zadejte nové heslo",
        long: "Heslo musí obsahovat alespoň jednu číslici, písmeno nebo mezeru a musí mít délku mezi 6 až 15 znaky",
        newPas: "Potvrzovací heslo se neshoduje s novým heslem",
        original: "Zadejte původní heslo",
        personal: 'Osobní nastavení',
        change: 'Změňte přezdívku a své údaje',
        username: 'Vaše uživatelské jméno',
        hide: 'Skrýt moji přezdívku',
        private: 'Soukromý profil',
        closing: 'Zavírání vašeho účtu',
        closeDatail: 'Svůj účet můžete zavřít kliknutím na tlačítko „Zavřít můj účet“ níže. Všechna vaše data budou trvale smazána bez možnosti budoucího obnovení (včetně jakýchkoli výhod VIP).',
        closeMy: 'Zavřít můj účet',
        social: 'Sociální',
        media: 'Připojit sociální média',
        connected: 'Připojeno',
        noConnect: 'Nepřipojeno',
        settings: 'Nastavení',
        account: 'Obecná nastavení vašeho účtu',
        email: 'E-mail',
        verify: 'Ověřte prosím svůj e-mail',
        factor: 'Dvoufaktorové ověřování',
        enable: 'Povolte dvoufaktorové ověřování, abyste ochránili svůj účet před neoprávněným přístupem.',
        prohibit: 'Zakázat',
        pas: 'Heslo',
        current: 'Vaše aktuální heslo',
        please: 'Zadejte prosím své aktuální heslo',
        newPassword: 'Nové heslo',
        paleseNew: 'Zadejte prosím nové heslo',
        repeat: 'Zopakujte heslo',
        pleaseCurrent: 'Zadejte prosím své aktuální heslo',
        changePas: 'Změnit heslo',
        sessions: 'Sezení',
        devices: 'Zkontrolujte svá zařízení',
        history: 'Historie her',
        referrals: 'Odkazy',
        VIP: 'VIP program',
        check: 'Zkontrolujte všechny své transakce',
        allTrans: 'Všechny transakce',
        withdrawal: 'Výběry',
        timeframe: 'Časový rámec',
        today: 'Dnes',
        deposits: 'Vklady',
        chackGame: 'Zkontrolujte všechny své hry',
        chain: 'Řetěz'
    },
    components: {
        search: "Hledat",
        sort: "Seřadit podle:",
        popular: "Populární",
        providers: "Poskytovatelé:",
        new: "Nové",
        hot: "Horké",
        notice: "Zatím žádné oznámení.",
        enable: "Povolit",
        cybet: "Cybet.com je ve vlastnictví společnosti Eskimo Adventures Ltd., registrační číslo 000043034, se sídlem na ulici Sea Urchin, San Pedro, Ambergris Caye, Belize. Kontaktní údaje: support{'@'}cybet.com. Licencováno vládou Anjouanu, Unie Komor pod licencí č. ALSI-202409034-FI2. Cybet.com plně vyhovuje regulačním standardům pro herní operace. Hazardní hry zahrnují finanční riziko a mohou vést k závislosti. Hrajte zodpovědně. Účastníci musí mít více než 18 let, aby se zúčastnili.",
        progress: "Váš VIP pokrok",
        discover: "Objevte ultimátní herní zážitek s exkluzivním VIP systémem od CY.GAMES!",
        link: "Váš odkaz",
        share: "Získejte 1 000,00 $ za každého přítele, kterého pozvete, plus až 25% provizi z jejich sázek. Užijte si stabilní provize, ať už vyhrají nebo prohrají, v našem kasinu a sázkové kanceláři. Začněte vydělávat nyní!",
        referralLink: "Váš referenční odkaz",
        code: "Váš referenční kód",
        yourReferrals: "Všechny vaše doporučení",
        receive: "Přijmout",
        game: "Všechny hry",
        winnings: "Výhry",
        loosing: "Prohry",
        wagered: "Vaši hráči vsadili",
        earned: "Vydělali jste",
        nothing: "Zatím není co uplatnit",
        join: "Připojte se k naší komunitě elitních hráčů a užijte si to nejlepší, co online hraní nabízí.",
        details: "Podrobnosti",
        unverified: "neověřeno",
        verified: "Email byl ověřen",
        type: "Typ transakce",
        date: "Datum",
        total: "Celkově vsazeno",
        methods: "Platební metody",
        status: "Stav",
        device: "Zařízení",
        location: "Umístění",
        IP: "IP adresa",
        last: "Poslední použití",
        tableGame: "Hra",
        bet: "Sázka",
        multiplayer: "Více hráčů",
        profit: "Zisk",
        searchGame: "Hledat hru",
        provider: "Poskytovatelé",
        ourGames: "Naše hry",
        allWins: "Všechny výhry",
        luckyWins: "Šťastné výhry",
        bigWins: "Velké výhry",
        latest: "Nejnovější sázky",
        player: "JMÉNO HRÁČE",
        time: "ČAS",
        amount: "ČÁSTKA",
        coefficent: "KOEFICIENT"
    },
    wallet: {
        noAddress: 'Adresa nesmí být prázdná',
        submit: 'Žádost o výběr byla odeslána, čeká se na potvrzení...',
        range: 'Zadejte číselnou částku v rámci výběrového limitu'
    }
};
