export default {
    main: {
        login: 'Se connecter',
        signup: 'S\'inscrire',
        language: 'Langue',
        toTop: 'Haut de page',
        licence: 'Licence',
        profile: 'Profil Personnel',
        nodata: 'Pas de données',
        logout: 'Se déconnecter',
        notify: 'Notifications',
        all: 'Tout',
        system: 'Avis système',
        activity: 'Activités',
        clear: 'Tout effacer',
        more: 'Charger plus',
        wallet: 'Portefeuille',
        deposit: 'Dépôt',
        withdraw: 'Retrait',
        trans: 'Transactions',
        currency: 'Devise de dépôt',
        network: 'Réseau',
        address: 'Adresse de dépôt',
        mini: 'Dépôt minimum',
        send: 'Envoyez uniquement des USDT à cette adresse de dépôt. Les fonds seront déposés automatiquement après 6 confirmations réseau. Les adresses de contrats intelligents ne sont pas prises en charge (Contactez-nous).',
        withAddress: 'Adresse de retrait',
        withEnter: 'Entrez votre adresse',
        amount: 'Montant du retrait',
        security: 'Pour des raisons de sécurité, les retraits importants ou suspects peuvent prendre entre 1 et 6 heures pour le processus de vérification. Merci de votre patience !',
        today: 'Aujourd\'hui',
        week: 'Cette semaine',
        month: 'Ce mois-ci',
        year: 'Cette année',
        myWallet: 'Mon portefeuille',
        available: 'Disponible',
        fee: 'Frais',
        chat: 'Chat',
        online: 'En ligne',
        message: 'Entrez un message',
        rules: 'Règles'
    },
    login: {
        email: "Veuillez saisir l'email",
        incorrect: "Le format de l'adresse e-mail est incorrect",
        password: "Veuillez saisir le mot de passe",
        characters: "Le mot de passe est une combinaison de chiffres et de lettres d'une longueur supérieure à 6",
        account: "Compte",
        login: "Connexion",
        register: "S'inscrire",
        userEmail: "Votre e-mail",
        enterEmail: "Entrez votre e-mail",
        userPas: "Votre mot de passe",
        enterPas: "Entrez votre mot de passe",
        forget: "Mot de passe oublié ?",
        or: "ou",
        agree: "J'ai lu et j'accepte les Conditions Générales.",
        resAndLogin: "S'inscrire et se connecter",
        policy: "Ce site est protégé par reCAPTCHA et la <span>Politique de confidentialité</span> et les <span>Conditions d'utilisation</span> de Google s'appliquent."
    },
    profile: {
        password: "Veuillez entrer un nouveau mot de passe",
        long: "Le mot de passe doit contenir au moins un chiffre, une lettre ou un espace blanc et être compris entre 6 et 15 caractères",
        newPas: "Le mot de passe de confirmation ne correspond pas au nouveau mot de passe",
        original: "Veuillez entrer le mot de passe original",
        personal: 'Paramètres personnels',
        change: 'Changer le surnom et vos données',
        username: 'Votre nom d’utilisateur',
        hide: 'Masquer mon surnom',
        private: 'Profil privé',
        closing: 'Fermeture de votre compte',
        closeDatail: 'Vous pouvez fermer votre compte en cliquant sur le bouton "Fermer mon compte" ci-dessous. Toutes vos données seront supprimées de façon permanente sans possibilité de récupération future (y compris les avantages VIP).',
        closeMy: 'Fermer mon compte',
        social: 'Social',
        media: 'Connecter les réseaux sociaux',
        connected: 'Connecté',
        noConnect: 'non connecté',
        settings: 'Paramètres',
        account: 'Paramètres généraux de votre compte',
        email: 'E-mail',
        verify: 'Veuillez vérifier votre e-mail',
        factor: 'Authentification à deux facteurs',
        enable: 'Activer l’authentification à deux facteurs pour protéger votre compte contre les accès non autorisés.',
        prohibit: 'Interdire',
        pas: 'Mot de passe',
        current: 'Votre mot de passe actuel',
        please: 'Veuillez entrer votre mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        paleseNew: 'Veuillez entrer votre nouveau mot de passe',
        repeat: 'Répéter le mot de passe',
        pleaseCurrent: 'Veuillez entrer votre mot de passe actuel',
        changePas: 'Changer le mot de passe',
        sessions: 'Sessions',
        devices: 'Vérifiez vos appareils',
        history: 'Historique des jeux',
        referrals: 'Références',
        VIP: 'Programme VIP',
        check: 'Vérifiez toutes vos transactions',
        allTrans: 'Toutes les transactions',
        withdrawal: 'Retraits',
        timeframe: 'Période',
        today: 'Aujourd’hui',
        deposits: 'Dépôts',
        chackGame: 'Vérifiez tous vos jeux',
        chain: 'Chaîne'
    },
    components: {
        search: "Rechercher",
        sort: "Trier par :",
        popular: "Populaire",
        providers: "Fournisseurs :",
        new: "Nouveau",
        hot: "Tendance",
        notice: "Pas de notification pour l'instant.",
        enable: "Activer",
        cybet: "Cybet.com est la propriété de Eskimo Adventures Ltd., numéro d'enregistrement 000043034, situé à Sea Urchin Street, San Pedro, Ambergris Caye, Belize. Contact : support{'@'}cybet.com. Licencié par le gouvernement d'Anjouan, Union des Comores sous le numéro de licence ALSI-202409034-FI2. Cybet.com respecte pleinement les normes réglementaires pour les opérations de jeu. Les jeux d'argent comportent un risque financier et peuvent être addictifs. Jouez de manière responsable. Les participants doivent avoir 18 ans ou plus pour jouer.",
        progress: "Votre progression VIP",
        discover: "Découvrez l'expérience de jeu ultime avec le système VIP exclusif de CY.GAMES !",
        link: "Votre lien",
        share: "Recevez 1 000,00 $ pour chaque ami que vous invitez, plus jusqu'à 25 % de commission sur leurs paris. Profitez de commissions régulières, qu'ils gagnent ou perdent, dans notre Casino et Sportsbook. Commencez à gagner maintenant !",
        referralLink: "Votre lien de parrainage",
        code: "Votre code de parrainage",
        yourReferrals: "Tous vos filleuls",
        receive: "Recevoir",
        game: "Tous les jeux",
        winnings: "Gains",
        loosing: "Pertes",
        wagered: "Vos joueurs ont parié",
        earned: "Vous avez gagné",
        nothing: "Rien à réclamer pour l'instant",
        join: "Rejoignez notre communauté de joueurs d'élite et profitez du meilleur de ce que le jeu en ligne a à offrir.",
        details: "Détails",
        unverified: "non vérifié",
        verified: "Email vérifié",
        type: "Type de transaction",
        date: "Date",
        total: "Total misé",
        methods: "Méthodes de paiement",
        status: "Statut",
        device: "Appareil",
        location: "Emplacement",
        IP: "Adresse IP",
        last: "Dernière utilisation",
        tableGame: "Jeu",
        bet: "Mise",
        multiplayer: "Multijoueur",
        profit: "Profit",
        searchGame: "Rechercher un jeu",
        provider: "Fournisseurs",
        ourGames: "Nos jeux",
        allWins: "Tous les gains",
        luckyWins: "Gains chanceux",
        bigWins: "Gains importants",
        latest: "Dernières mises",
        player: "NOM DU JOUEUR",
        time: "HEURE",
        amount: "MONTANT",
        coefficent: "COEFFICIENT"
    },
    wallet: {
        noAdress: 'L\'adresse ne peut pas être vide',
        submit: 'La demande de retrait a été soumise, en attente de confirmation...',
        range: 'Veuillez saisir un montant dans la plage de retrait'
    }
};
