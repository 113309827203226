import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-70e4042c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "spinner Cy_font_SemiBold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.buttonClass, "base-button Cy_font_SemiBold"])
    }, [
        _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
            [_vShow, _ctx.loading]
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2));
}
